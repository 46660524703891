import { useEffect } from "react";
import { DefaultPage } from "../DefaultPage/DefaultPage";
import HeadlineItem from "../HeadlineItem/HeadlineItem";
import Hyperlink from "../Hyperlink/Hyperlink";
import LargeHeadlineItem from "../LargeHeadlineItem/LargeHeadlineItem";
import ListItem from "../ListItem/ListItem";
import TextItem from "../TextItem/TextItem";
import styles from "./PageStyles.module.scss";

export default function DeinNavigatorPage() {
    useEffect(() => {
		document.title = "Navigator Bauantrag";
	}, []);

    return (
        <DefaultPage>
            <LargeHeadlineItem margin="2em 0 0 0">Dein Navigator</LargeHeadlineItem>
            <div className={styles.sidebysidetop}>
                <img className={styles.imagevertical} src="/assets/dein_navigator.png" />
                <div className={styles.listtop}>
                    <TextItem>Wähle Dein Bauvorhaben aus.</TextItem>
                    <TextItem margin="0.2em 0 0 0">
                        Es folgt eine kurze Beschreibung und eine Übersicht der notwendigen{" "}
                        <Hyperlink text="Bauvorlagen" href="/Dein-Ratgeber/Was-sind-Bauvorlagen" />. Baurechtliche
                        Begriffe und Stichworte erklären wir in:
                    </TextItem>
                    <TextItem margin="0.2em 0 0 0">
                        <Hyperlink text="Dein Ratgeber" href="/Dein-Ratgeber/" />
                    </TextItem>

                    <HeadlineItem>
                        Ohne Angabe von persönlichen Daten kannst Du bei{" "}
                        <Hyperlink text="Dein Angebot" href="/Dein-Angebot" /> erfahren wie viel Du bei aktiver Mithilfe
                        sparst!
                    </HeadlineItem>
                </div>
            </div>

            <LargeHeadlineItem>Dein Bauvorhaben</LargeHeadlineItem>
            <ul className={styles.list} style={{ gap: "0.7em" }}>
                        <ListItem margin="0">
                            <Hyperlink text="Abbruch" href="/Dein-Navigator/Abbruch/"></Hyperlink>
                        </ListItem>
                        <ListItem margin="0">
                            <Hyperlink text="Neubau (Anbau)" href="/Dein-Navigator/Neubau-Anbau/"></Hyperlink>
                        </ListItem>
                        <ListItem margin="0">
                            <Hyperlink
                                text="Umbau Sanierung Modernisierung"
                                href="/Dein-Navigator/Umbau-Sanierung-Modernisierung"
                            ></Hyperlink>
                        </ListItem>
                        <ListItem margin="0">
                            <Hyperlink text="Nutzungsänderung" href="/Dein-Navigator/Nutzungsaenderung"></Hyperlink>
                        </ListItem>
                        <ListItem margin="0">
                            <Hyperlink
                                text="Der Brief vom Baurechtsamt"
                                href="/Dein-Navigator/Der-Brief-vom-Bauamt"
                            ></Hyperlink>
                        </ListItem>
                    </ul>

            <LargeHeadlineItem>Deine Baugenehmigung</LargeHeadlineItem>
            <TextItem>
                Ein erfolgreich absolvierter Prozess bis zur Erteilung einer{" "}
                <Hyperlink text="Baugenehmigung" href="/Dein-Ratgeber/Bauamt-Bauverstaendige-Fristen-Baugenehmigung/" />{" "}
                ist kein Zufallsprodukt, sondern folgerichtiges Ergebnis konzentrierter und strukturierter Arbeit, d.h.
                wir:
            </TextItem>

            <ul className={styles.list} style={{ gap: "0.7em", listStyleType: "numbers" }}>
                <ListItem margin="1em 0 0 0">erkennen und verstehen die Absichten unserer Bauherren</ListItem>
                <ListItem>klären am Beginn des Projekts den Kostenrahmen</ListItem>
                <ListItem>informieren uns vorab zu den Chancen der Genehmigungsfähigkeit</ListItem>
                <ListItem>sind in jeder Hinsicht erstklassige Entwurfsarchitekten</ListItem>
                <ListItem>können auch mit 3D Visualisierungen überraschen</ListItem>
                <ListItem>
                    verfügen über langjährige Routine bei der Erstellung der{" "}
                    <Hyperlink text="Bauvorlagen" href="/Dein-Ratgeber/Was-sind-Bauvorlagen/" />
                </ListItem>
            </ul>
        </DefaultPage>
    );
}
