import { useEffect } from "react";
import { DefaultPage } from "../DefaultPage/DefaultPage";
import LargeHeadlineItem from "../LargeHeadlineItem/LargeHeadlineItem";
import TextItem from "../TextItem/TextItem";

export default function ImpressumPage() {
    useEffect(() => {
		document.title = "Bauantrag Impressum";
	}, []);
    return (
        <DefaultPage>
            <LargeHeadlineItem margin="2em 0 0 0">Impressum</LargeHeadlineItem>
            <TextItem>
                Informationspflicht laut § 5 TMG.
                <br />
                wackerkollegen Architekten und Ingenieure
                <br />
                Rebmannstraße 27
                <br />
                70180 Stuttgart
                <br />
                Deutschland
                <br />
                UID-Nummer: DE 325149932
                <br />
                Tel.: 0049 711 23 68 596
                <br />
                E-Mail: office@wackerkollegen.de
                <br />
                Berufsbezeichnung: Architekt, Dipl. Ing. (FH)
                <br />
                vertreten durch den Gesellschafter
                <br />
                Ron A. Wacker
                <br />
                Berufshaftpflichtversicherung
                <br />
                R+V Allgemeine Versicherung AG, Mittlerer Pfad, 70499 Stuttgart
                <br />
                Räumliche Geltung: Deutschland
                <br />
                Quelle: Erstellt mit dem Impressum Generator von AdSimple in Kooperation mit hashtagmann.de
                <br />
                Der Dipl.-Ing. (FH) Ron A. Wacker (AL.-Nr. 56530) ist eingetragenes Mitglied der Architektenkammer
                Baden-Württemberg, Danneckerstrasse 54, 70182 Stuttgart. Die Berechtigung zur Führung der
                Berufsbezeichnung 'Architekt' ergibt sich aus der Eintragung in die Architektenliste der
                Architektenkammer Baden-Württemberg.
                <br />
                Die berufsrechtlichen Bestimmungen, insbesondere das Architektengesetz Baden-Württemberg sowie die
                Berufsordnung, sind einsehbar auf der Homepage der Architektenkammer Baden-Württemberg:
                http://www.akbw.de.
                <br />
                EU-Streitschlichtung
                <br />
                Gemäß Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten (ODR-Verordnung) möchten wir
                Sie über die Online-Streitbeilegungsplattform (OS-Plattform) informieren.
                <br />
                Verbraucher haben die Möglichkeit, Beschwerden an die Online Streitbeilegungsplattform der Europäischen
                Kommission unter http://ec.europa.eu/odr?tid=321133316 zu richten. Die dafür notwendigen Kontaktdaten
                finden Sie oberhalb in unserem Impressum.
                <br />
                Wir möchten Sie jedoch darauf hinweisen, dass wir nicht bereit oder verpflichtet sind, an
                Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
                <br />
                Haftung für Inhalte dieser Webseite
                <br />
                Wir entwickeln die Inhalte dieser Webseite ständig weiter und bemühen uns korrekte und aktuelle
                Informationen bereitzustellen. Laut Telemediengesetz (TMG) §7 (1) sind wir als Diensteanbieter für
                eigene Informationen, die wir zur Nutzung bereitstellen, nach den allgemeinen Gesetzen verantwortlich.
                Leider können wir keine Haftung für die Korrektheit aller Inhalte auf dieser Webseite übernehmen,
                speziell für jene die seitens Dritter bereitgestellt wurden. Als Diensteanbieter im Sinne der §§ 8 bis
                10 sind wir nicht verpflichtet, die von ihnen übermittelten oder gespeicherten Informationen zu
                überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
                <br />
                Unsere Verpflichtungen zur Entfernung von Informationen oder zur Sperrung der Nutzung von Informationen
                nach den allgemeinen Gesetzen aufgrund von gerichtlichen oder behördlichen Anordnungen bleiben auch im
                Falle unserer Nichtverantwortlichkeit nach den §§ 8 bis 10 unberührt.
                <br />
                Sollten Ihnen problematische oder rechtswidrige Inhalte auffallen, bitte wir Sie uns umgehend zu
                kontaktieren, damit wir die rechtswidrigen Inhalte entfernen können. Sie finden die Kontaktdaten im
                Impressum.
                <br />
                Haftung für Links auf dieser Webseite
                <br />
                Unsere Webseite enthält Links zu anderen Webseiten für deren Inhalt wir nicht verantwortlich sind.
                Haftung für verlinkte Webseiten besteht für uns nicht, da wir keine Kenntnis rechtswidriger Tätigkeiten
                hatten und haben, uns solche Rechtswidrigkeiten auch bisher nicht aufgefallen sind und wir Links sofort
                entfernen würden, wenn uns Rechtswidrigkeiten bekannt werden.
                <br />
                Wenn Ihnen rechtswidrige Links auf unserer Webseite auffallen, bitte wir Sie uns zu kontaktieren. Sie
                finden die Kontaktdaten im Impressum.
                <br />
                Urheberrechtshinweis
                <br />
                Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos) unterliegen dem Urheberrecht der
                Bundesrepublik Deutschland. Bitte fragen Sie uns bevor Sie die Inhalte dieser Webseite verbreiten,
                vervielfältigen oder verwerten wie zum Beispiel auf anderen Webseiten erneut veröffentlichen. Falls
                notwendig, werden wir die unerlaubte Nutzung von Teilen der Inhalte unserer Seite rechtlich verfolgen.
                <br />
                Sollten Sie auf dieser Webseite Inhalte finden, die das Urheberrecht verletzen, bitten wir Sie uns zu
                kontaktieren.
                <br />
                Bildernachweis
                <br />
                Die Bilder, Fotos und Grafiken auf dieser Webseite sind urheberrechtlich geschützt.
                <br />
                Die Bilderrechte liegen bei den folgenden Fotografen und Unternehmen:
                <br />
                Marcel Czeczinski, Cranachstraße 37, 40235 Düsseldorf https://www.strichfiguren.de
                <br />
                wackerkollegen, https://www.wackerkollegen.de
                <br />
                Quelle: Erstellt mit dem Datenschutz Generator von AdSimple in Kooperation mit 123familie.de
            </TextItem>
        </DefaultPage>
    );
}
