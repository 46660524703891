import React, { useState } from 'react';
import styles from './HamburgerIcon.module.scss';

interface HamburgerIconProps {
  onClick: () => void;
}

const HamburgerIcon: React.FC<HamburgerIconProps> = ({ onClick }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleClick = () => {
    setIsExpanded(!isExpanded);
    onClick();
  };

  return (
    <div
      className={`${styles.container} ${isExpanded ? styles.change : ''}`}
      onClick={handleClick}
    >
      <div className={styles.bar1}></div>
      <div className={styles.bar2}></div>
      <div className={styles.bar3}></div>
    </div>
  );
};

export default HamburgerIcon;