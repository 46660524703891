import { useEffect } from "react";
import { DefaultPage } from "../DefaultPage/DefaultPage";
import HeadlineItem from "../HeadlineItem/HeadlineItem";
import Hyperlink from "../Hyperlink/Hyperlink";
import LargeHeadlineItem from "../LargeHeadlineItem/LargeHeadlineItem";
import TextItem from "../TextItem/TextItem";
import styles from "./PageStyles.module.scss";

export default function DeinRatgeberBauamtBauverstaendigeFristenBaugenehmigungPage() {
    useEffect(() => {
		document.title = "Bauantrag Bauverständige Fristen Genehmigung";
	}, []);

    return (
        <DefaultPage>
            <LargeHeadlineItem margin="2em 0 0 0">Bauamt Bauverständige Fristen Baugenehmigung</LargeHeadlineItem>
            <div className={styles.sidebysidetop}>
                <img className={styles.imagevertical} src="/assets/baurecht.png" />
                <TextItem minwidth="400px" >Rechtsgrundlage: LBO und LBOVVO</TextItem>
            </div>
            <HeadlineItem>Das Bauamt, Baurechtsamt, Bürgerbüro</HeadlineItem>
            <TextItem>
                Zur Klärung der baurechtlichen Umstände informierst Du Dich beim zuständigen Bauamt. Du benötigst
                Postleitzahl, Straße und Hausnummer, etwas Zeit und einen kompetenten Berater. Die Informationen sind
                öffentlich zugänglich, auch wenn Du nicht Eigentümer des Grundstücks oder der Immobilie bist. Oftmals
                sind alle relevanten Informationen auf einem Blatt (Grundstücksinformationsblatt/PLARIS)
                zusammengefasst, wie z.B. der rechtskräftige{" "}
                <Hyperlink text="Bebauungsplan" href="/Dein-Ratgeber/Was-ist-ein-Bebauungsplan/" />, gültige Satzungen
                und Verordnungen.
                <br />
                <TextItem margin="0.5em 0 0 0">
                    <i><b>Tipp: </b>Notiere für jedes Einzelthema das Rechercheergebnis auf einem separaten Blatt.</i>
                </TextItem>
            </TextItem>

            <HeadlineItem>Die Bauverständigen</HeadlineItem>
            <TextItem>
                Dein Bauantrag ist eingereicht. Wie geht es weiter?
                <br />
                Der Bauverständige beim zuständigen Bauamt, z.B. Landkreis, Stadtkreis oder Verwaltungsgemeinschaft, hat
                Dich 10 Arbeitstage (§54(1) LBO BW) nach Eingang der Bauvorlagen über den Ausgang der Prüfung zu
                informieren.
                <br />
                Dein Bauantrag wird nur bei absoluter Vollständigkeit, der eindeutigen Lesbarkeit von Plänen und
                Anträgen und mit entsprechender Bauvorlageberechtigung bearbeitet. Du kannst davon ausgehen, dass der
                Bauverständige mit allen relevanten Umständen des Baurechts zur Beurteilung Deines Verfahrens vertraut
                ist.
                <TextItem margin="0.5em 0 0 0">
                    <i><b>Übrigens: </b>der Bauverständige haftet persönlich für seine Entscheidungen.</i>
                </TextItem>
            </TextItem>

            <HeadlineItem>Fristen für den Bauantrag</HeadlineItem>
            <TextItem>
                Die Dauer der Bearbeitungszeit ist in §54 LBO BW klar geregelt: 10 Arbeitstage nach Eingang der
                Bauvorlagen erhältst Du einen Prüfbescheid. Sind die Unterlagen unvollständig oder mangelhaft wird eine
                Frist zur Nachbesserung gewährt. Ist der Antrag bearbeitungsfähig, beginnt das Verfahren mit der
                Einholung von Stellungnahmen der am Bauantrag zu beteiligenden Stellen. Spätestens nach einem Monat
                müssen sich die beteiligenden Stellen zu Deinem Bauantrag äußern. Dem Bauamt verbleiben danach noch
                weitere zwei Monate (ausnahmsweise auch drei Monate) für den Bescheid Deines Bauantrages. Im
                vereinfachten Baugenehmigungsverfahren verkürzt sich diese Frist um einen Monat.
            </TextItem>

            <HeadlineItem>Die Baugenehmigung</HeadlineItem>
            <TextItem>
                §58 LBO BW beginnt mit den Worten „Die Baugenehmigung ist zu erteilen, wenn …“, und das bedeutet im
                Klartext eben nicht „vielleicht" oder „mal sehen“. „Ist“ beschreibt den Rechtsanspruch auf Erteilung der
                Baugenehmigung, allerdings nur dann, wenn Dein Architekt alles richtig gemacht hat.
            </TextItem>
        </DefaultPage>
    );
}
