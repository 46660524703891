import { ReactNode } from "react";
import PageHeader from "../PageHeader/PageHeader";
import styles from "./DefaultPage.module.scss";
import PageFooter from "../PageFooter/PageFooter";

interface Props {
    children: ReactNode;
}

export const DefaultPage: React.FC<Props> = ({ children }) => {
    return (
        <div className={styles.defaultpage}>
            <div className={styles.page}>
                <div>
                    <PageHeader />
                    <div className={styles.children}>{children}</div>
                </div>
                <PageFooter />
            </div>
        </div>
    );
};
