import React, { useState, useEffect } from "react";
import { DefaultPage } from "../DefaultPage/DefaultPage";
import HeadlineItem from "../HeadlineItem/HeadlineItem";
import LargeHeadlineItem from "../LargeHeadlineItem/LargeHeadlineItem";
import ListItem from "../ListItem/ListItem";
import TextItem from "../TextItem/TextItem";
import styles from "./PageStyles.module.scss";

//https://bauantrag-bw.de/Dein-Angebot/

function OBERGRENZE(zahl: number, schritt: number): number {
    return Math.ceil(zahl / schritt) * schritt;
}

function UNTERGRENZE(zahl: number, schritt: number): number {
    return Math.floor(zahl / schritt) * schritt;
}

export default function DeinAngebotPage() {
	useEffect(() => {
		document.title = "Bauantrag Angebot";
	}, []);

    const [offerWithSupport, setOfferWithSupport] = useState(0.0);
    const [offerWithoutSupport, setOfferWithoutSupport] = useState(0.0);
    const [yoursavings, setYoursavings] = useState(0.0);

    const [checkboxValues, setCheckboxValues] = useState({
        checkbox1: false,
        checkbox2: false,
        checkbox3: false,
        checkbox4: false,
    });
    const [inputValues, setInputValues] = useState({
        distanceToStuttgart: 0,
        bgfSize: 0,
    });

    //default values set by user:
    const BAUKOSTEN = 1250; //Euro/m²
    const KG300 = 60; //Percent
    const KILOMETERPAUSCHALE = 0.3; //Euro/km
    const STUNDENSATZ = 95; //Euro/h
    const FAHRZEIT = 55; //km/h
    const TERMINEVORORT = 4; //Termine
    const BAUGESUCHPAUSCHALE = 1999; //Termine
    const GRUNDLAGENERMITTLUNG = 325; //Euro
    const VORENTWURFPERCENT = 13; //percent
    const ENTWURFPERCENT = 28; //percent
    const handleChange = () => {
        //no input provided
            
        const isInvalid1 = Number.isNaN(inputValues.bgfSize) || inputValues.bgfSize <= 0;       
        const isInvalid2 = Number.isNaN(inputValues.distanceToStuttgart) || inputValues.distanceToStuttgart <= 0;
            
        (document.getElementById("bgfsizeID") as HTMLInputElement).style.borderColor = isInvalid1 ? "#ff0000" : '#ffffff';
        (document.getElementById("distancetostuttgartID") as HTMLInputElement).style.borderColor = isInvalid2 ? "#ff0000" : '#ffffff';
    
        if (isInvalid1 || isInvalid2) {
            setOfferWithoutSupport(0);
            setOfferWithSupport(0);
            setYoursavings(0);
            return;
        }

        const baukosten = inputValues.bgfSize * BAUKOSTEN; //m² price
        const kg300Price = baukosten * (KG300 / 100.0);
        const fahrzeitPrice = (inputValues.distanceToStuttgart / FAHRZEIT) * 2 * STUNDENSATZ;
        const kmPauschalePrice = inputValues.distanceToStuttgart * 2 * TERMINEVORORT * KILOMETERPAUSCHALE;
        let priceWithoutSupport = OBERGRENZE(
            (0.4112 * Math.pow(kg300Price, 0.8941) + 0.002797 * kg300Price) * 0.27,
            25
        );
        const mindestHonorar = OBERGRENZE((BAUGESUCHPAUSCHALE + kmPauschalePrice + Math.round(fahrzeitPrice)), 25);
        const degressionsformel = OBERGRENZE(priceWithoutSupport, 25);
        priceWithoutSupport = mindestHonorar > degressionsformel ? mindestHonorar : degressionsformel;


        const savingsThroughCheckbox = [
            checkboxValues.checkbox1 ? GRUNDLAGENERMITTLUNG : 0,
            checkboxValues.checkbox2
                ? OBERGRENZE(((kmPauschalePrice + fahrzeitPrice) / TERMINEVORORT) * (TERMINEVORORT - 1), 25)
                : 0,
            checkboxValues.checkbox3 ? UNTERGRENZE((priceWithoutSupport * VORENTWURFPERCENT) / 100.0, 25) : 0,
            checkboxValues.checkbox4 ? UNTERGRENZE(priceWithoutSupport * (ENTWURFPERCENT / 100.0), 25) : 0,
        ];

        savingsThroughCheckbox.map((item, index) => {
            (document.getElementById(`checkbox${index + 1}_savings`) as HTMLElement).innerText = (-item) + " €";
        });

        const savingsWithSupport = savingsThroughCheckbox.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
        );
        setOfferWithoutSupport(Number(priceWithoutSupport.toFixed(2)));
        setOfferWithSupport(priceWithoutSupport - savingsWithSupport);
        setYoursavings(-savingsWithSupport);
    };
    useEffect(() => {
        handleChange();
    });

    const handleCheckboxChange = (checkboxName: string, isChecked: boolean) => {
        setCheckboxValues((prevCheckboxValues) => ({
            ...prevCheckboxValues,
            [checkboxName]: isChecked,
        }));
    };
    const handleInputChange = (inputName: string, value: number) => {
        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [inputName]: value,
        }));
    };

    return (
        <DefaultPage>
            <LargeHeadlineItem margin="2em 0 0 0">Dein Angebot</LargeHeadlineItem>
            <div className={styles.sidebysidetop}>
                <img className={styles.imagevertical} src="/assets/angebot.png" />
                <div className={styles.listtop}>
                    <TextItem>
                        Unser Onlineangebot hilft Dir einzuschätzen, in welchem Abschnitt der Antragstellung unsere
                        Hilfe notwendig ist und an welcher Stelle Du aktiv mithelfen kannst.
                    </TextItem>
                </div>
            </div>
            <LargeHeadlineItem>Dein Vorteil</LargeHeadlineItem>
            <TextItem>
                Spare Geld und entlaste unser Arbeitszeitkontingent.
                <br />
                Wir benötigen nur einige Angaben um Deine Mithilfe in der Honorarberechnung zu berücksichtigen.
            </TextItem>

            <LargeHeadlineItem>Dein Projekt</LargeHeadlineItem>
            <TextItem>
                Hallo Ihr wackeren Kollegen, hier kommen zwei Zahlen und meine Antworten. Ich freue mich auf Euer
                Angebot.
            </TextItem>

            <TextItem margin="2em 0 0 0">
                <div className={styles.list} style={{ listStyleType: "none", alignItems: "flex-start" }}>
                    <ListItem width="100%">
                        <div className={styles.textinputwrapper}>
                            <TextItem width="80%" margin="0">
                                Wie weit entfernt ist Dein Baugrundstück von Stuttgart?
                            </TextItem>
                            <div className={styles.sidebysideraw}>
                                <input
                                    onChange={(e) => handleInputChange("distanceToStuttgart", parseInt(e.target.value))}
                                    min="0"
                                    step="1"
                                    id="distancetostuttgartID"
                                    type="number"
                                    className={styles.numberinput}
                                />
                                <TextItem width="50px" margin="0 0 0 1em">
                                    km
                                </TextItem>
                            </div>
                        </div>
                    </ListItem>
                    <ListItem width="100%">
                        <div className={styles.textinputwrapper}>
                            <TextItem width="80%" margin="0">
                                Wie groß soll die Bruttogeschossfläche (BGF) sein?
                            </TextItem>
                            <div className={styles.sidebysideraw}>
                                <input
                                    onChange={(e) => handleInputChange("bgfSize", parseInt(e.target.value))}
                                    min="0"
                                    id="bgfsizeID"
                                    step="1"
                                    type="number"
                                    className={styles.numberinput}
                                />
                                <TextItem width="50px" margin="0 0 0 1em">
                                    m²
                                </TextItem>
                            </div>
                        </div>
                    </ListItem>
                    <ListItem width="100%" margin="1em 0 0 0">
                        <div className={styles.textinputwrapper + " " + styles.borderwrapper}>
                            <HeadlineItem margin="0">Dein Angebot, wenn wir uns um alles kümmern sollen</HeadlineItem>
                            <HeadlineItem margin="0">{offerWithoutSupport} €</HeadlineItem>
                        </div>
                    </ListItem>

                    <HeadlineItem>Sei aktiver Teil des Projekts und spare Geld, z.B. bei:</HeadlineItem>
                    <ListItem width="100%">
                        <HeadlineItem margin="0.5em 0 0 0">Grundlagenermittlung</HeadlineItem>
                        <div className={styles.textinputwrapper}>
                            <TextItem width="80%" margin="0">
                                Liegenschaftskarte, Grundbuchauszug, Baulastenverzeichnis, Bebauungsplan kann ich selbst
                                organisieren und Euch zur Verfügung stellen. (*1)
                            </TextItem>
                            <div className={styles.sidebysideraw}>
                                <input
                                    checked={checkboxValues.checkbox1}
                                    onChange={(e) => handleCheckboxChange("checkbox1", e.target.checked)}
                                    type="checkbox"
                                    className={styles.checkbox}
                                />
                                <TextItem id="checkbox1_savings" width="70px" margin="0 0 0 1em"/>
                            </div>
                        </div>
                    </ListItem>
                    <ListItem width="100%">
                        <HeadlineItem margin="0.5em 0 0 0">Ortstermine</HeadlineItem>
                        <div className={styles.textinputwrapper}>
                            <TextItem width="80%" margin="0">
                                Ein Termin vor Ort ist enthalten, im Weiteren kommunizieren wir über webbasierte
                                Meetingplattformen (*2)
                            </TextItem>
                            <div className={styles.sidebysideraw}>
                                <input
                                    checked={checkboxValues.checkbox2}
                                    onChange={(e) => handleCheckboxChange("checkbox2", e.target.checked)}
                                    type="checkbox"
                                    className={styles.checkbox}
                                />
                                <TextItem id="checkbox2_savings" width="70px" margin="0 0 0 1em"/>
                            </div>
                        </div>
                    </ListItem>
                    <ListItem width="100%">
                        <HeadlineItem margin="0.5em 0 0 0">Vorentwurf</HeadlineItem>
                        <div className={styles.textinputwrapper}>
                            <TextItem width="80%" margin="0">
                                Ich habe bereits einen ausgearbeiteten Vorentwurf, für diese Leistung benötige ich keine
                                weitere Unterstützung (*3)
                            </TextItem>
                            <div className={styles.sidebysideraw}>
                                <input
                                    checked={checkboxValues.checkbox3}
                                    onChange={(e) => handleCheckboxChange("checkbox3", e.target.checked)}
                                    type="checkbox"
                                    className={styles.checkbox}
                                />
                                <TextItem id="checkbox3_savings" width="70px" margin="0 0 0 1em"/>
                            </div>
                        </div>
                    </ListItem>
                    <ListItem width="100%">
                        <HeadlineItem margin="0.5em 0 0 0">Entwurfsplanung</HeadlineItem>
                        <div className={styles.textinputwrapper}>
                            <TextItem width="80%" margin="0">
                                Ich habe bereits einen fix fertigen Entwurf und benötige nur noch die
                                Genehmigungsplanung (*3,*4)
                            </TextItem>
                            <div className={styles.sidebysideraw}>
                                <input
                                    checked={checkboxValues.checkbox4}
                                    onChange={(e) => handleCheckboxChange("checkbox4", e.target.checked)}
                                    type="checkbox"
                                    className={styles.checkbox}
                                />
                                <TextItem id="checkbox4_savings" width="70px" margin="0 0 0 1em"/>
                            </div>
                        </div>
                    </ListItem>

                    <ListItem width="100%" margin="1em 0 0 0">
                        <div className={styles.textinputwrapper + " " + styles.borderwrapper}>
                            <HeadlineItem margin="0">Dein Angebot, wenn Du uns unterstützen möchtest</HeadlineItem>
                            <HeadlineItem margin="0">{offerWithSupport} €</HeadlineItem>
                        </div>
                    </ListItem>

                    <ListItem width="100%" margin="1em 0 0 0">
                        <div className={styles.textinputwrapper}>
                            <HeadlineItem margin="0">Dein Ersparnis</HeadlineItem>
                            <HeadlineItem margin="0">
                                {(offerWithoutSupport == 0 ? 0 : (yoursavings / offerWithoutSupport) * 100).toFixed()} %
                            </HeadlineItem>
                            <HeadlineItem margin="0">{yoursavings} €</HeadlineItem>
                        </div>
                    </ListItem>

                    <ListItem width="100%" margin="1em 0 0 0">
                        <TextItem>*1: die Kosten richten sich nach der örtlichen Satzung der Gemeinde.</TextItem>
                        <TextItem>*2: wir kommunizieren über MS Teams</TextItem>
                        <TextItem>*3: wir prüfen Deine Angaben, die Ersparnis kann sich auch verringern</TextItem>
                        <TextItem>
                            *4: hast Du auch CAD fähige Datenformate, kann die Ersparnis auch höher ausfallen
                        </TextItem>
                        <TextItem>
                            <b>Alle Preisangaben Brutto incl. gesetzlicher Umsatzsteuer</b>
                        </TextItem>
                    </ListItem>
                </div>
            </TextItem>
        </DefaultPage>
    );
}
