import { ReactNode } from "react"
import styles from '../Pages/PageStyles.module.scss';

type Props = {
    children?: ReactNode,
    width?: string,
    minwidth?: string,
    margin?: string,
    textalign?:  'left' | 'right' | 'center' | 'justify',
    id?: string
}

export default function TextItem({children, width, margin, textalign, id, minwidth}: Props){
    return <div id={id} style={{width: width, minWidth:minwidth, margin: margin, textAlign: textalign}} className={styles.text}>
        {children}
    </div>
}