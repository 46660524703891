import { useEffect } from "react";
import { DefaultPage } from "../DefaultPage/DefaultPage";
import Hyperlink from "../Hyperlink/Hyperlink";
import LargeHeadlineItem from "../LargeHeadlineItem/LargeHeadlineItem";
import ListItem from "../ListItem/ListItem";
import TextItem from "../TextItem/TextItem";
import styles from "./PageStyles.module.scss";

export default function DeinArchitektPage() {
    useEffect(() => {
        document.title = "Architekturbüro";
    }, []);

    return (
        <DefaultPage>
            <LargeHeadlineItem margin="2em 0 0 0">Dein Architekt</LargeHeadlineItem>
            <div className={styles.sidebysidetop}>
                <img className={styles.image} src="/assets/puzzle.png" />
                <div className={styles.listtop}>
                    <TextItem>
                        bw-bauantrag.de ist ein digitaler Service der{" "}
                        <Hyperlink text="wackerkollegen" href="http://wackerkollegen.de/" />, Architekten und
                        Ingenieure.
                    </TextItem>
                    <TextItem>
                        Wir sind Mitglied der{" "}
                        <Hyperlink
                            text="Architektenkammer"
                            href="https://www.akbw.de/kammer/datenbanken/architektenliste/detail/eintrag/ronald-wacker-15176"
                        />{" "}
                        Baden-Württemberg und haftpflichtversichert bei der R+V Allgemeine Versicherung AG.
                    </TextItem>
                    <TextItem>
                        Mit langjähriger Erfahrung und zielgerichtetem Engagement führen wir Dich sicher und erfolgreich
                        durch das Antragsverfahren
                    </TextItem>
                </div>
            </div>
            <LargeHeadlineItem margin="3em 0 0 0">Wie findest Du den richtigen Architekten?</LargeHeadlineItem>
            <TextItem>
                Es gibt zahlreiche Möglichkeiten an Kontaktdaten von Architekten zu gelangen, z.B. in Telefon- und
                Branchenbüchern,{" "}
                <Hyperlink
                    text="Architektenlisten"
                    href="https://www.akbw.de/kammer/datenbanken/architektenliste/detail/eintrag/ronald-wacker-15176"
                />
                , durch Empfehlungen von Freunden, bei zufriedenen Bauherren oder auch mit einem Klick bei der
                Internetsuche.
                <br /> Ein Bauvorhaben ist eine große Herausforderung für alle Beteiligten und die Wahl des richtigen
                Architekten eine Sache des <Hyperlink text="Vertrauens" href="http://wackerkollegen.de/" />.
            </TextItem>
            <LargeHeadlineItem>Starte Dein Projekt</LargeHeadlineItem>
            <div className={styles.sidebyside}>
                <TextItem width="50%">
                    Jede Reise beginnt mit dem ersten Schritt, beginne im{" "}
                    <Hyperlink text="Navigator:" href="/Dein-Navigator/" />
                </TextItem>
                <TextItem>
                    <ul className={styles.list}>
                        <ListItem>
                            <Hyperlink text="Abbruch" href="/Dein-Navigator/Abbruch/" />
                        </ListItem>
                        <ListItem>
                            <Hyperlink text="Neubau (Anbau)" href="/Dein-Navigator/Neubau-Anbau/" />
                        </ListItem>
                        <ListItem>
                            <Hyperlink
                                text="Umbau Sanierung Modernisierung"
                                href="/Dein-Navigator/Umbau-Sanierung-Modernisierung/"
                            />
                        </ListItem>
                        <ListItem>
                            <Hyperlink text="Nutzungsänderung" href="/Dein-Navigator/Nutzungsaenderung/" />
                        </ListItem>
                        <ListItem>
                            <Hyperlink text="Der Brief vom Baurechtsamt" href="/Dein-Navigator/Der-Brief-vom-Bauamt/" />
                        </ListItem>
                    </ul>
                </TextItem>
            </div>
            <TextItem margin="3em 0 0 0">
                <i>
                    <b>Übrigens: </b>
                    Aktuelle Bewertungen zu unserem Büro und viele weitere Dienstleistungen rund um das Thema Bauen und
                    Wohnen findest Du auf dieser{" "}
                    <Hyperlink
                        text="Internetplattformen."
                        href="https://www.construyo.de/dienstleister/nsan5VhSQTpQ9OZWLLj7"
                    />
                </i>
            </TextItem>
        </DefaultPage>
    );
}
