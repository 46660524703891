import { useEffect } from "react";
import { DefaultPage } from "../DefaultPage/DefaultPage";
import Hyperlink from "../Hyperlink/Hyperlink";
import LargeHeadlineItem from "../LargeHeadlineItem/LargeHeadlineItem";
import ListItem from "../ListItem/ListItem";
import TextItem from "../TextItem/TextItem";
import styles from "./PageStyles.module.scss";

export default function DeinRatgeberWasisteinBebauungsplanPage() {
    useEffect(() => {
		document.title = "Bauantrag Bebauungsplan";
	}, []);

    return (
        <DefaultPage>
            <LargeHeadlineItem margin="2em 0 0 0">Was ist ein Bebauungsplan?</LargeHeadlineItem>
            <div className={styles.sidebysidetop}>
                <img className={styles.imagevertical} src="/assets/baurecht.png" />
                <div className={styles.listtop}>
                    <TextItem>
                    Befindet sich Dein Baugrundstück oder Deine Immobilie im Bereich eines rechtskräftigen <Hyperlink text="Bebauungsplanes" href="" />, ist das prinzipiell ein gutes Zeichen.
                    </TextItem>
                    <TextItem>Der Bebauungsplan hat in der Regel einen Plan- und einen Textteil.</TextItem>
                    <TextItem>Je nach Datum des Inkrafttretens findest Du hier die gültigen Rechtsvorschriften, die zur Beurteilung Deines Vorhabens maßgeblich sind.</TextItem>
                </div>
            </div>

            <TextItem margin="1.5em 0 1.5em 0">Wichtige Beurteilungskriterien zur Genehmigungsfähigkeit sind:</TextItem>
            <TextItem>
                <ul className={styles.list} style={{ gap: "0.7em"}}>
                    <ListItem><Hyperlink text="Baugesetzbuch" href="/Dein-Ratgeber/Bundesgesetze"/> (BauGB),</ListItem>
                    <ListItem><Hyperlink text="Baunutzungsverordnung" href="/Dein-Ratgeber/Bundesgesetze"/> (BauNVO),</ListItem>
                    <ListItem><Hyperlink text="Planzeichenverordnung" href="/Dein-Ratgeber/Bundesgesetze"/> (PlanZV),</ListItem>
                    <ListItem><Hyperlink text="Landesbauordnung" href="/Dein-Ratgeber/Landesgesetze"/>  (LBO)</ListItem>
                </ul>
            </TextItem>

            </DefaultPage>
    );
}