import { useEffect } from "react";
import { mailtoURL } from "../..";
import { DefaultPage } from "../DefaultPage/DefaultPage";
import Hyperlink from "../Hyperlink/Hyperlink";
import LargeHeadlineItem from "../LargeHeadlineItem/LargeHeadlineItem";
import TextItem from "../TextItem/TextItem";
import styles from "./PageStyles.module.scss";

export default function DeinNavigatorDerBriefvomBauamtPage() {
    useEffect(() => {
        document.title = "Bauamt Brief";
    }, []);

    return (
        <DefaultPage>
            <LargeHeadlineItem margin="2em 0 0 0">Der Brief vom Bauamt</LargeHeadlineItem>
            <div className={styles.sidebysidetop}>
                <img className={styles.imagevertical} src="/assets/brief.png" />
                <div className={styles.listtop}>
                    <TextItem>
                        Meistens aus heiterem Himmel und nicht unbedingt im zeitlichen Zusammenhang mit Bautätigkeiten
                        ist er auf einmal da:
                    </TextItem>
                    <TextItem>Der Brief vom Bauamt.</TextItem>
                    <TextItem>
                        Inhaltlich werden die unterschiedlichsten Tatbestände oder Verstöße gegen öffentlich-rechtliche
                        Vorschriften angemahnt,
                        <br />
                        sachlich sind diese Schreiben meistens mit Fristsetzung und Bußgeldandrohung versehen.{" "}
                    </TextItem>
                </div>
            </div>

            <TextItem margin="3em 0 0 0">
                <b>Schiebe das Problem nicht auf die lange Bank. Es löst sich sicher nicht von allein.</b>
            </TextItem>

            <TextItem margin="2em 0 0 0">
                <div className={styles.sidebyside}>
                    <TextItem width="40%">
                        <Hyperlink text="Vielen Dank für die Informationen" href="/Dein-Bauantrag" />
                        <br />
                        <Hyperlink text="Ich benötige keine weitere Unterstützung" href="/Dein-Bauantrag" />
                    </TextItem>
                    <TextItem width="50%" textalign="right">
                        <Hyperlink text="Kontaktiere uns per E-Mail oder Anruf." href={mailtoURL} />
                        <br />

                        <Hyperlink text="Meistens können wir helfen" href={mailtoURL} />
                    </TextItem>
                </div>
            </TextItem>

            <TextItem margin="3em 0 0 0">
                <i>
                    <b>Übrigens: </b>
                    Nach der letzten Fristsetzung, ist vor der Vollstreckung.
                </i>
            </TextItem>
        </DefaultPage>
    );
}
