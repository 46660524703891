import { useEffect } from "react";
import { DefaultPage } from "../DefaultPage/DefaultPage";
import HeadlineItem from "../HeadlineItem/HeadlineItem";
import Hyperlink from "../Hyperlink/Hyperlink";
import LargeHeadlineItem from "../LargeHeadlineItem/LargeHeadlineItem";
import ListItem from "../ListItem/ListItem";
import TextItem from "../TextItem/TextItem";
import styles from "./PageStyles.module.scss";


export default function DeinBauantrag_Page() {
    useEffect(() => {
        document.title = "Bauantrag";
    }, []);

    return (
        <DefaultPage>
            <LargeHeadlineItem margin="2em 0 0 0">Dein Bauantrag</LargeHeadlineItem>
            <div className={styles.sidebysidetop}>
                <img className={styles.image} src="/assets/megafon.png" />
                <div className={styles.sidebysidetop2}>
                    <TextItem margin="1em 2em 0 0" minwidth="250px">
                        Wir sind die richtige Wahl.
                    </TextItem>
                    <TextItem>
                        Sei neugierig und erfahre mit unserem <Hyperlink text="Onlinerechner" href="/Dein-Angebot/" />,
                        wie viel Dein Bauantrag kostet und wie wieviel Du sparen kannst, wenn Du uns aktiv unterstützt.
                    </TextItem>
                </div>
            </div>
            <TextItem margin="3em 0 0 0">
                Das Portal der Landesregierung Baden-Württemberg{" "}
                <Hyperlink text="service-bw.de" href="https://www.service-bw.de/zufi" /> liefert nach und nach alle zur
                Erstellung von Bauanträgen notwendigen Dienstleistungen und Informationen, die wir in den letzten 30
                Jahren buchstäblich zu Fuß aus verschiedenen Quellen zusammentragen mussten. Damit erleichtert der
                digitale Bauantrag unsere tägliche Arbeit als Architekturbüro entscheidend.
            </TextItem>
            <LargeHeadlineItem>Was kostet ein Bauantrag?</LargeHeadlineItem>
            <TextItem>
                Ein genehmigungsfähiger Bauantrag ist zwar auch weiterhin mit der Erstellung verschiedener Bauvorlagen
                und der Beauftragung von Fachleuten verbunden. Allerdings sehen wir für alle interessierten
                Antragsteller eine große Chance, den Prozess bis zur Erteilung einer Baugenehmigung besser zu verstehen
                und selbst aktiv zu werden. Unser <Hyperlink text="Onlinerechner" href="/Dein-Angebot/" />{" "}
                bw-bauantrag.de hilft Dir dabei einzuschätzen, in welchem Abschnitt der Antragstellung unsere Hilfe
                notwendig ist und an welcher Stelle Du aktiv mithelfen kannst. Du sparst Geld und entlastest unser
                Arbeitszeitkontingent.
            </TextItem>
            <LargeHeadlineItem>Starte Dein Projekt</LargeHeadlineItem>
            <div className={styles.sidebyside}>
                <TextItem minwidth="50%">
                    Es begleitet Dich dabei <Hyperlink text="Dein Navigator" href="/Dein-Navigator/" /> und bei
                    Interesse an Fachfragen <br />
                    <Hyperlink text="Dein Ratgeber." href="/Dein-Ratgeber/" />
                </TextItem>
                <TextItem>
                    <ul className={styles.list}>
                        <ListItem>
                            <Hyperlink text="Abbruch" href="/Dein-Navigator/Abbruch/" />
                        </ListItem>
                        <ListItem>
                            <Hyperlink text="Neubau (Anbau)" href="/Dein-Navigator/Neubau-Anbau/" />
                        </ListItem>
                        <ListItem>
                            <Hyperlink text="Umbau Sanierung Modernisierung" href="/Dein-Navigator/Umbau-Sanierung-Modernisierung/" />
                        </ListItem>
                        <ListItem>
                            <Hyperlink text="Nutzungsänderung" href="/Dein-Navigator/Nutzungsaenderung/" />
                        </ListItem>
                        <ListItem>
                            <Hyperlink text="Der Brief vom Bauamt" href="/Dein-Navigator/Der-Brief-vom-Bauamt/" />
                        </ListItem>
                    </ul>
                </TextItem>
            </div>
            <TextItem margin="3em 0 0 0">
                <i>
                    <b>Übrigens:</b> Der Gesetzgeber verabschiedete im Jahr 2017 das Onlinezugangsgesetz und
                    verpflichtete damit Länder und Kommunen, ihre Verwaltungsleistungen ab 01.01.2023 auch digital
                    anzubieten. Du kannst nunmehr von zu Hause oder unterwegs einen Wohnsitz anmelden, den
                    Personalausweis verlängern, einen Verein gründen und auch einen Bauantrag einreichen.
                </i>
            </TextItem>
        </DefaultPage>
    );
}
