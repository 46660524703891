import { useEffect } from "react";
import { mailtoURL } from "../..";
import { DefaultPage } from "../DefaultPage/DefaultPage";
import Hyperlink from "../Hyperlink/Hyperlink";
import LargeHeadlineItem from "../LargeHeadlineItem/LargeHeadlineItem";
import TextItem from "../TextItem/TextItem";
import styles from "./PageStyles.module.scss";

//https://bauantrag-bw.de/Dein-Architekt/Kontakt/

export default function DeinArchitektKontaktPage() {
    useEffect(() => {
		document.title = "Kontakt";
	}, []);

    return (
        <DefaultPage>
            <LargeHeadlineItem margin="2em 0 0 0">
                <Hyperlink text="Kontakt!" href={mailtoURL} />
            </LargeHeadlineItem>
            <div className={styles.sidebysidetop}>
                <img className={styles.image} src="/assets/kontakt.png" />
                <div className={styles.listtop} style={{width: 'max-content'}}>
                    <TextItem>Die erste Beratung ist kostenlos!</TextItem>
                    <TextItem>
                        Wir antworten auf <Hyperlink text="E-Mail Anfragen" href={mailtoURL} /> innerhalb kürzester
                        Zeit.
                    </TextItem>
                    <TextItem>Du kannst uns auch Bilder oder PDF Dateien schicken, oder uns anrufen.</TextItem>
                    <TextItem>Wir nehmen uns Zeit für Dein Anliegen.</TextItem>
                </div>
            </div>
        </DefaultPage>
    );
}
