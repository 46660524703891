import { useEffect } from "react";
import { DefaultPage } from "../DefaultPage/DefaultPage";
import HeadlineItem from "../HeadlineItem/HeadlineItem";
import Hyperlink from "../Hyperlink/Hyperlink";
import LargeHeadlineItem from "../LargeHeadlineItem/LargeHeadlineItem";
import TextItem from "../TextItem/TextItem";
import styles from "./PageStyles.module.scss";

export default function DeinRatgeberBundesgesetzePage() {
    useEffect(() => {
        document.title = "Bauantrag Bundesgesetze";
    }, []);

    return (
        <DefaultPage>
            <LargeHeadlineItem margin="2em 0 0 0">Bundesgesetzgebung</LargeHeadlineItem>
            <img className={styles.imagevertical} src="/assets/deinRatgeber.png" />

            <HeadlineItem>Das Baugesetzbuch (BauGB)</HeadlineItem>
            <TextItem>
                Das Baugesetzbuch (BauGB) ist eine Gesetzgebung der Bundesrepublik Deutschland und regelt somit
                Bauangelegenheiten auf Bundesebene. In Ausübung Ihrer originären Staatsgewalt (Artikel 30 Grundgesetz)
                erlassen somit 16 Bundesländer auch 16 verschiedene Landesbauordnungen bzw. Bauordnungen.
            </TextItem>

            <HeadlineItem>Die Baunutzungsverordnung (BauNVO)</HeadlineItem>
            <TextItem>
                Die Baunutzungsverordnung bestimmt, wie es der Name bereits erahnen lässt, Art und Maß der baulichen
                Nutzung eines Grundstücks sowie Angaben zur Bauweise und überbaubaren Grundstücksfläche.
            </TextItem>

            <HeadlineItem>Die Planzeichenverordnung (PlanZV)</HeadlineItem>
            <TextItem>
                Eine Linie, ein Symbol = eine Bedeutung, dafür gibt es die Planzeichenverordnung. Die Internetsuche
                hierzu hilft Dir, verschiedene Striche und Symbole der Bauleitplanung (
                <Hyperlink text="Bebauungsplan" href="/Dein-Ratgeber/Was-ist-ein-Bebauungsplan/" />) zu verstehen.
            </TextItem>

            <HeadlineItem>Das Raumordnungsgesetz (ROG)</HeadlineItem>
            <TextItem>
                Das Raumordnungsgesetz soll für den Gesamtraum der Bundesrepublik Deutschland eine ausgewogene
                Siedlungs- und Freiraumstruktur entwickeln und Naturräume angemessen schützen und entwickeln. Das ist
                eine anspruchsvolle Aufgabe, wollen wir die fortschreitende Zersiedlung der Landschaft vermeiden,
                gleichzeitig über eine leistungsfähige Infrastruktur verfügen, ländliche Räume entwickeln und
                Erholungsgebiete fördern.
            </TextItem>

            <TextItem margin="3em 0 0 0">
                <i>
                    <b>Übrigens: </b>
                    Bebauungspläne beziehen sich immer auf BauGB, BauNVO und PlanZV, achte auf die Datumsangaben der
                    Bekanntmachungen. Interessierte finden die vollständigen Textausgaben im Internet.
                </i>
            </TextItem>
        </DefaultPage>
    );
}
