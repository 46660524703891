import styles from "./HorizontalNavigationBar.module.scss";
import HorizontalNavigationBarItem from "./HorizontalNavigationBarItem/HorizontalNavigationBarItem";

export default function HorizontalNavigationBar() {
    return (
        <div className={styles.navigationbar}>
            <HorizontalNavigationBarItem href="/Dein-Bauantrag/" text="Dein Bauantrag" />
            <HorizontalNavigationBarItem href="/Dein-Architekt/" text="Dein Architekt">
                <HorizontalNavigationBarItem href="/Dein-Architekt/Kontakt/" text="Kontakt!" />
            </HorizontalNavigationBarItem>
            <HorizontalNavigationBarItem href="/Dein-Navigator/" text="Dein Navigator">
                <HorizontalNavigationBarItem href="/Dein-Navigator/Abbruch/" text="Abbruch" />
                <HorizontalNavigationBarItem href="/Dein-Navigator/Neubau-Anbau/" text="Neubau (Anbau)" />
                <HorizontalNavigationBarItem
                    href="/Dein-Navigator/Umbau-Sanierung-Modernisierung/"
                    text="Umbau Sanierung Modernisierung"
                />
                <HorizontalNavigationBarItem href="/Dein-Navigator/Nutzungsaenderung/" text="Nutzungsänderung" />
                <HorizontalNavigationBarItem href="/Dein-Navigator/Der-Brief-vom-Bauamt/" text="Der Brief vom Bauamt" />
            </HorizontalNavigationBarItem>
            <HorizontalNavigationBarItem href="/Dein-Angebot/" text="Dein Angebot" />
            <HorizontalNavigationBarItem href="/Dein-Ratgeber/" text="Dein Ratgeber">
                <HorizontalNavigationBarItem href="/Dein-Ratgeber/Bundesgesetze/" text="Bundesgesetze" />
                <HorizontalNavigationBarItem href="/Dein-Ratgeber/Landesgesetze/" text="Landesgesetze" />
                <HorizontalNavigationBarItem
                    href="/Dein-Ratgeber/Was-ist-ein-Bebauungsplan/"
                    text="Was ist ein Bebauungsplan?"
                />
                <HorizontalNavigationBarItem
                    href="/Dein-Ratgeber/Was-sind-Gebaeudeklassen/"
                    text="Was sind Gebäudeklassen?"
                />
                <HorizontalNavigationBarItem href="/Dein-Ratgeber/Was-ist-ein-Lageplan/" text="Was ist ein Lageplan?" />
                <HorizontalNavigationBarItem href="/Dein-Ratgeber/Was-sind-Bauvorlagen/" text="Was sind Bauvorlagen?" />
                <HorizontalNavigationBarItem
                    href="/Dein-Ratgeber/Bauamt-Bauverstaendige-Fristen-Baugenehmigung/"
                    text="Bauamt Bauverständige Fristen Baugenehmigung?"
                />
                <HorizontalNavigationBarItem
                    href="/Dein-Ratgeber/Der-Antrag-auf-Abweichungen,-Ausnahmen-oder-Befreiungen/"
                    text="Der Antrag auf Abweichungen, Ausnahmen oder Befreiungen?"
                />
            </HorizontalNavigationBarItem>
        </div>
    );
}
