import { useEffect } from "react";
import { DefaultPage } from "../DefaultPage/DefaultPage";
import Hyperlink from "../Hyperlink/Hyperlink";
import LargeHeadlineItem from "../LargeHeadlineItem/LargeHeadlineItem";
import ListItem from "../ListItem/ListItem";
import TextItem from "../TextItem/TextItem";
import styles from "./PageStyles.module.scss";

export default function DeinNavigatorAbbruchPage() {
	useEffect(() => {
		document.title = "Bauantrag Abbruch";
	}, []);

    return (
        <DefaultPage>
            <LargeHeadlineItem margin="2em 0 0 0">Abbruch baulicher Anlagen</LargeHeadlineItem>
            <div className={styles.sidebysidetop}>
                <img className={styles.imagevertical} src="/assets/gluehlampe.png" />
                <div className={styles.listtop}>
                    <TextItem>
                        Unter dem Abbruch baulicher Anlagen ist deren teilweise oder vollständige Beseitigung zu
                        verstehen.
                    </TextItem>
                    <TextItem>Rechtsgrundlagen sind:</TextItem>
                    <TextItem>
                        §50 LBO
                        <br />
                        der Anhang zu §50 Abs.1. LBO
                    </TextItem>
                </div>
            </div>

            <LargeHeadlineItem margin="3em 0 0 0">
                Der Abbruch baulicher Anlagen ist verfahrensfrei, wenn:
            </LargeHeadlineItem>
            <TextItem>
                <ul className={styles.list}>
                    <ListItem>
                        diese Anlagen auch verfahrensfrei errichtet werden dürfen, s. Anhang{" "}
                        <Hyperlink text="LBO" href="/Dein-Navigator/Abbruch/" /> zu §50 Abs.1
                    </ListItem>
                    <ListItem>
                        es sich um freistehende Gebäude der{" "}
                        <Hyperlink text="Gebäudeklassen" href="/Dein-Ratgeber/Was-sind-Gebaeudeklassen" /> 1 und 3 handelt
                    </ListItem>
                    <ListItem>
                        es um sonstige Anlagen geht, die keine Gebäude sind, mit einer Höhe bis zu 10 m.
                    </ListItem>
                </ul>
            </TextItem>

            <TextItem margin="2em 0 0 0">
                Alle anderen Abbrucharbeiten sind im{" "}
                <Hyperlink text="Kenntnisgabe Verfahren" href="/Dein-Ratgeber/Was-sind-Bauvorlagen/" /> zu beantragen.
                Beachte dabei denkmalschutzrechtliche Vorschriften!
            </TextItem>

            <LargeHeadlineItem>Bauvorlagen</LargeHeadlineItem>
            <TextItem margin="1em 0 0 0">
                <ul className={styles.list}>
                    <ListItem>
                        Antragsformular Abbruch baulicher Anlagen im{" "}
                        <Hyperlink text="Kenntnisgabe Verfahren" href="/Dein-Ratgeber/Was-sind-Bauvorlagen/" />
                    </ListItem>
                    <ListItem>Abfallverwertungskonzept nach § 3 Abs. 4 LKreiWiG</ListItem>
                    <ListItem>
                        <Hyperlink text="Lageplan" href="/Dein-Ratgeber/Was-ist-ein-Lageplan/" />
                    </ListItem>
                    <ListItem>Abbruchunternehmen / Tragwerksplaner</ListItem>
                    <ListItem>Bauleiter/in-Erklärung nach § 1 Abs. 1 Nr. 6 LBOVVO</ListItem>
                </ul>
            </TextItem>
            <TextItem margin="3em 0 0 0">
                <i>
                    <b>Übrigens: </b>
                    Auch beim Abbruch kann einiges schiefgehen, deshalb fordert der Gesetzgeber erfahrene
                    Abbruchunternehmer, Tragwerksplaner und Bauleiter zu beauftragen.
                </i>
            </TextItem>
        </DefaultPage>
    );
}
