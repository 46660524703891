import { useEffect } from "react";
import { DefaultPage } from "../DefaultPage/DefaultPage";
import Hyperlink from "../Hyperlink/Hyperlink";
import LargeHeadlineItem from "../LargeHeadlineItem/LargeHeadlineItem";
import TextItem from "../TextItem/TextItem";
import styles from "./PageStyles.module.scss";

export default function DeinRatgeberDerAntragaufAbweichungenAusnahmenoderBefreiungenPage() {
    useEffect(() => {
		document.title = "Bauantrag Abweichung Ausnahme Befreiung";
	}, []);

    return (
        <DefaultPage>
            <LargeHeadlineItem margin="2em 0 0 0">
                Der Antrag auf Abweichungen, Ausnahmen oder Befreiungen?
            </LargeHeadlineItem>
            <div className={styles.sidebysidetop}>
                <img className={styles.imagevertical} src="/assets/baurecht.png" />
                <div className={styles.listtop}>
                    <TextItem>
                        Die Beurteilung von Abweichungen, Ausnahmen oder Befreiungen ist einzelfallbezogen und kann bei
                        unterschiedlichen{" "}
                        <Hyperlink
                            text="Bauverständigen"
                            href="/Dein-Ratgeber/Bauamt-Bauverstaendige-Fristen-Baugenehmigung/"
                        />{" "}
                        unterschiedliche Ergebnisse zur Folge haben.
                    </TextItem>
                    <TextItem>
                        Generell besteht kein Rechtsanspruch auf die Erteilung von Abweichungen, Ausnahmen oder
                        Befreiungen.
                    </TextItem>
                </div>
            </div>

            <TextItem>
                <b>Abweichungen</b> sind bauordnungsrechtlich betrachtet nur dann zulässig, wenn örtliche
                Bauvorschriften durch ein gleichwertiges Sicherheitsniveau erreicht werden könnten und städtebaulich
                betrachtet nur dann vertretbar, wenn sie nach Maßgabe einer gerechten Abwägung anhand § 1 Abs. 6 und 7{" "}
                <Hyperlink text="BauGB" href="/Dein-Ratgeber/Bundesgesetze"/> auch zulässiger Inhalt eines{" "}
                <Hyperlink text="Bebauungsplans" href="/Dein-Ratgeber/Was-ist-ein-Bebauungsplan/"/> sein könnten.
            </TextItem>

            <TextItem>
                <b>Ausnahmen</b> vom <Hyperlink text="Bebauungsplan" href="/Dein-Ratgeber/Was-ist-ein-Bebauungsplan/" /> können nur dann zugelassen werden, wenn
                sie im <Hyperlink text="Bebauungsplan" href="/Dein-Ratgeber/Was-ist-ein-Bebauungsplan/"/> nach Art und
                Umfang ausdrücklich vorgesehen sind (vgl. § 31 Abs. 1{" "}
                <Hyperlink text=" BauGB" href="/Dein-Ratgeber/Bundesgesetze"/>
                ). Ob eine Ausnahmegenehmigung erteilt werden kann, ist also im jeweiligen Bebauungsplan festgelegt.
            </TextItem>

            <TextItem>
                <b>Befreiungen</b> stellen eine Durchbrechung des bauleitplanerischen Konzepts dar. Sie ist im Gegensatz zu
                Ausnahmen nicht im <Hyperlink text="Bebauungsplan" href="/Dein-Ratgeber/Was-ist-ein-Bebauungsplan/"/> vorgesehen und ermöglichen Abweichungen von den Festsetzungen des{" "}
                <Hyperlink text="Bebauungsplans" href="/Dein-Ratgeber/Was-ist-ein-Bebauungsplan/"/>.
            </TextItem>
        </DefaultPage>
    );
}
