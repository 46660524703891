import { DefaultPage } from "../DefaultPage/DefaultPage";
import HeadlineItem from "../HeadlineItem/HeadlineItem";
import Hyperlink from "../Hyperlink/Hyperlink";
import LargeHeadlineItem from "../LargeHeadlineItem/LargeHeadlineItem";
import ListItem from "../ListItem/ListItem";
import TextItem from "../TextItem/TextItem";
import styles from "./PageStyles.module.scss";
import { useEffect } from "react";

export default function DeinRatgeberLandesgesetzePage() {
    useEffect(() => {
        document.title = "Bauantrag Landesgesetze";
    }, []);

    return (
        <DefaultPage>
            <LargeHeadlineItem margin="2em 0 0 0">Landesgesetzgebung</LargeHeadlineItem>
            <img className={styles.imagevertical} src="/assets/deinRatgeber.png" />

            <HeadlineItem>Die Verfahrensverordnung zur Landesbauordnung (LBOVVO)</HeadlineItem>
            <TextItem>
                Grundsätzlich unterscheiden sich die 16 verschiedenen Landesbauordnungen bzw. Bauordnung nur in wenigen
                Details, da sich alle auf das <Hyperlink text="BauGB" href="/Dein-Ratgeber/Bundesgesetze" /> beziehen.
            </TextItem>
            <TextItem margin="1.5em 0 1.5em 0">
                Die Landesbauordnung Baden-Württemberg enthält 9 Teile und einen Anhang.
            </TextItem>

            <TextItem>
                <ul className={styles.list} style={{ gap: "0.7em", listStyleType: "decimal" }}>
                    <ListItem>Teil Allgemeine Vorschriften (§§ 1 - 3)</ListItem>
                    <ListItem>Teil Das Grundstück und seine Bebauung (§§ 4 - 10)</ListItem>
                    <ListItem>Teil Allgemeine Anforderungen an die Bauausführung (§§ 11 - 16c)</ListItem>
                    <ListItem>Teil Bauprodukte (§§ 17 - 25)</ListItem>
                    <ListItem>Teil Der Bau und seine Teile (§§ 26 - 33)</ListItem>
                    <ListItem>Teil Einzelne Räume, Wohnungen und besondere Anlagen (§§ 34 - 40)</ListItem>
                    <ListItem>Teil Am Bau Beteiligte, Baurechtsbehörden (§§ 41 - 48)</ListItem>
                    <ListItem>Teil Verwaltungsverfahren, Baulasten (§§ 49 - 72)</ListItem>
                    <ListItem>
                        Teil Rechtsvorschriften, Ordnungswidrigkeiten, Übergangs- und Schlussvorschriften (§§ 73 - 79)
                    </ListItem>
                    <TextItem margin="0 0.7em 0 0">Anhang</TextItem>
                </ul>
            </TextItem>

            <HeadlineItem>Die Landesbauordnung Baden-Württemberg (LBO)</HeadlineItem>
            <TextItem margin="1.5em 0 1.5em 0"> Hier wird in 7 Abschnitten geregelt:</TextItem>
            <TextItem>
                <ul className={styles.list} style={{ gap: "0.7em", listStyleType: "decimal" }}>
                    <ListItem>
                        Allgemeine Vorschriften zu den Bauvorlagen im Kenntnisgabe Verfahren und im
                        Genehmigungsverfahren
                    </ListItem>
                    <ListItem>Inhalt und Verfasser einzelner Bauvorlagen</ListItem>
                    <ListItem>Bauvorlagen in besonderen Fällen</ListItem>
                    <ListItem>Bauvorlagen in besonderen Verfahren</ListItem>
                    <ListItem>
                        Erstellung der bautechnischen Nachweise, bautechnische Prüfung und bautechnische Prüfbestätigung
                    </ListItem>
                    <ListItem>Festlegung von Grundriss und Höhenlage der Gebäude auf dem Baugrundstück</ListItem>
                    <ListItem>Ordnungswidrigkeiten, Inkrafttreten</ListItem>
                    <TextItem margin="0 0.7em 0 0">
                        Anlage - Gemeinden und Gemeindeteile in besonders erdbebengefährdeten Gebieten
                    </TextItem>
                </ul>
            </TextItem>

            <HeadlineItem>Verordnungen, Vorschriften und Vordrucke</HeadlineItem>
            <TextItem margin="1.5em 0 1.5em 0">Der Vollständigkeit halber seien erwähnt:</TextItem>
            <TextItem>
                <ul className={styles.list} style={{ gap: "0.7em" }}>
                    <ListItem>Die Allgemeine Ausführungsverordnung zur Landesbauordnung (LBOAVO)</ListItem>
                    <ListItem>
                        Die Verordnungen für verschiedene Bereiche (BauSVO, CPIVO, FeuVO, GaVO, VkVO, FliegBautenZuVO,
                        EltBauVO, VStättVO)
                    </ListItem>
                    <ListItem>Die Verwaltungsvorschriften von Brandschutz bis Statistik</ListItem>
                    <ListItem>Die Vordrucke für bauliche Verfahren</ListItem>
                </ul>
            </TextItem>

            <TextItem margin="2em 0 0 0">
                Es können weitere regionale Richtlinien, Erlasse und Hinweise zur Beurteilung eines Bauvorhabens
                notwendig sein, die je nach Stadt und Gemeinde variieren.
            </TextItem>

            <TextItem margin="3em 0 0 0">
                <i>
                    <b>Übrigens: </b>
                    LBO und LBOVVO sind die wesentlichsten Regelwerke im Baugenehmigungsverfahren. Inhaltliche Kenntnis
                    und formale Anwendung sind entscheidend für eine erfolgreiche Antragstellung. Interessierte finden
                    die vollständigen Textausgaben im Internet.
                </i>
            </TextItem>
        </DefaultPage>
    );
}
