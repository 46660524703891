import { ReactNode } from "react"
import TextItem from "../TextItem/TextItem";

type Props = {
    children: ReactNode,
    width?: string,
    margin?: string,
}

export default function ListItem({children, width, margin}: Props){
    return <TextItem width={width} margin={margin ?? "0"}>
        <li>{children}</li>
    </TextItem>
}