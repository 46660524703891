import styles from "./PageFooter.module.scss";
import Hyperlink from "../Hyperlink/Hyperlink";
import TextItem from "../TextItem/TextItem";
import { mailtoURL } from "../..";

export default function PageFooter() {
    return (
        <div className={styles.pagefooter}>
            <div className={styles.footeritems}>
                <TextItem margin="0">
                    bw-bauantrag.de ist ein digitaler Service der{" "}
                    <Hyperlink text="wackerkollegen" href="http://wackerkollegen.de/wackerkollegen/Kontakt/" />,
                    Architekten und Ingenieure
                </TextItem>
            </div>
            
            <div className={styles.footeritems}>
                <TextItem margin="0">
                    Rebmannstraße 27, 
                    <br />
                    70180 Stuttgart
                    <br />
                    Telefon: 0049 711 2368596<br />
                    E-Mail:{" "}
                    <Hyperlink text="office@wackerkollegen.de" href={mailtoURL} />
                </TextItem>
            </div>
            <div className={styles.footeritems}>
                <TextItem margin="0" width="100%" textalign="justify">
                    <Hyperlink text="Datenschutzerklärung" href="/Dein-Architekt/Datenschutzerklaerung/" />
                </TextItem>
                <TextItem margin="0" textalign="justify">
                    <Hyperlink text="Impressum" href="/Dein-Architekt/Impressum/" />
                </TextItem>
            </div>
        </div>
    );
}
