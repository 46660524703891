import './index.css';
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import DeinArchitektPage from './components/Pages/DeinArchitektPage';
import DeinBauantragPage from './components/Pages/DeinBauantragPage';
import DeinArchitektKontaktPage from './components/Pages/DeinArchitektKontaktPage';
import DeinNavigatorAbbruchPage from './components/Pages/DeinNavigatorAbbruchPage';
import DeinNavigatorNeubauAnbau from './components/Pages/DeinNavigatorNeubauAnbauPage';
import DeinNavigatorUmbauSanierungModernisierungPage from './components/Pages/DeinNavigatorUmbauSanierungModernisierungPage';
import DeinNavigatorNutzungsaenderungPage from './components/Pages/DeinNavigatorNutzungsaenderungPage';
import DeinNavigatorDerBriefvomBauamtPage from './components/Pages/DeinNavigatorDerBriefvomBauamtPage';
import DeinAngebotPage from './components/Pages/DeinAngebotPage';
import DatenschutzerklaerungPage from './components/Pages/DatenschutzerklaerungPage';
import ImpressumPage from './components/Pages/ImpressumPage';
import DeinRatgeberBundesgesetzePage from './components/Pages/DeinRatgeberBundesgesetzePage';
import DeinRatgeberLandesgesetzePage from './components/Pages/DeinRatgeberLandesgesetzePage';
import DeinRatgeberWasisteinBebauungsplanPage from './components/Pages/DeinRatgeberWasisteinBebauungsplanPage';
import DeinRatgeberWassindGebaeudeklassenPage from './components/Pages/DeinRatgeberWassindGebaeudeklassenPage';
import DeinRatgeberWasisteinLageplanPage from './components/Pages/DeinRatgeberWasisteinLageplanPage';
import DeinRatgeberWassindBauvorlagenPage from './components/Pages/DeinRatgeberWassindBauvorlagenPage';
import DeinRatgeberBauamtBauverstaendigeFristenBaugenehmigungPage from './components/Pages/DeinRatgeberBauamtBauverstaendigeFristenBaugenehmigungPage';
import DeinRatgeberDerAntragaufAbweichungenAusnahmenoderBefreiungenPage from './components/Pages/DeinRatgeberDerAntragaufAbweichungenAusnahmenoderBefreiungenPage';
import DeinRatgeberPage from './components/Pages/DeinRatgeberPage';
import DeinNavigatorPage from './components/Pages/DeinNavigatorPage';

export const mailtoURL = "mailto:office@wackerkollegen.de?subject=bw-bauantrag.de_Anfrage&body=Hallo%20Ihr%20wackeren%20Kollegen.%0AIch%20habe%20Eure%20Website%20besucht%20und%20interessiere%20mich%20f%C3%BCr%20folgendes%20Thema%3A%0A%0AIch%20freue%20mich%20von%20Euch%20zu%20h%C3%B6ren.%0ABis%20bald.";


//Alle routen zu den jeweiligen Seiten:
const router = createBrowserRouter([
  {
    path: "/", //Der Pfad im Browser
    element: <DeinBauantragPage/>, //Das Element, welches auf der Seite angezeigt wird
  },
  {
    path: "/Dein-Bauantrag",
    element: <DeinBauantragPage/>,
  },
  {
    path: "/Dein-Architekt",
    element: <DeinArchitektPage/>
  },
  {
    path: "/Dein-Architekt/Kontakt",
    element: <DeinArchitektKontaktPage/>
  },
  {
    path: "/Dein-Navigator/Abbruch",
    element: <DeinNavigatorAbbruchPage/>
  },
  {
    path: "/Dein-Navigator/Neubau-Anbau",
    element: <DeinNavigatorNeubauAnbau/>
  },
  {
    path: "/Dein-Navigator/Umbau-Sanierung-Modernisierung",
    element: <DeinNavigatorUmbauSanierungModernisierungPage/>
  },
  {
    path: "/Dein-Navigator/Nutzungsaenderung",
    element: <DeinNavigatorNutzungsaenderungPage/>
  },
  {
    path: "/Dein-Navigator/Der-Brief-vom-Bauamt",
    element: <DeinNavigatorDerBriefvomBauamtPage/>
  },
  {
    path: "/Dein-Angebot/",
    element: <DeinAngebotPage/>
  },
  {
    path: "/Dein-Architekt/Impressum",
    element: <ImpressumPage/>
  },
  {
    path: "/Dein-Architekt/Datenschutzerklaerung",
    element: <DatenschutzerklaerungPage/>
  },
  {
    path: "/Dein-Ratgeber/Bundesgesetze",
    element: <DeinRatgeberBundesgesetzePage/>
  },
  {
    path: "/Dein-Ratgeber/Landesgesetze",
    element: <DeinRatgeberLandesgesetzePage/>
  },
  {
    path: "/Dein-Ratgeber/Was-ist-ein-Bebauungsplan",
    element: <DeinRatgeberWasisteinBebauungsplanPage/>
  },
  {
    path: "/Dein-Ratgeber/Was-sind-Gebaeudeklassen",
    element: <DeinRatgeberWassindGebaeudeklassenPage/>
  },
  {
    path: "/Dein-Ratgeber/Was-ist-ein-Lageplan",
    element: <DeinRatgeberWasisteinLageplanPage/>
  },
  {
    path: "/Dein-Ratgeber/Was-sind-Bauvorlagen",
    element: <DeinRatgeberWassindBauvorlagenPage/>
  },
  {
    path: "/Dein-Ratgeber/Bauamt-Bauverstaendige-Fristen-Baugenehmigung",
    element: <DeinRatgeberBauamtBauverstaendigeFristenBaugenehmigungPage/>
  },
  {
    path: "/Dein-Ratgeber/Der-Antrag-auf-Abweichungen,-Ausnahmen-oder-Befreiungen",
    element: <DeinRatgeberDerAntragaufAbweichungenAusnahmenoderBefreiungenPage/>
  },
  {
    path: "/Dein-Ratgeber/",
    element: <DeinRatgeberPage/>
  },
  {
    path: "/Dein-Navigator/",
    element: <DeinNavigatorPage/>
  }
]);

createRoot(document.getElementById("root") as HTMLElement).render(
  <RouterProvider router={router} />
);