import React, { useState } from "react";
import styles from "./NavigationBarItem.module.scss";

interface NavigationBarItemProps {
    href: string;
    text: string;
    children?: React.ReactNode;
}

const NavigationBarItem: React.FC<NavigationBarItemProps> = ({ href, children, text }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleCollapse = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={styles.navbaritem}>
            <div className={styles.navbaritemheader}>
                <a className={styles.text} href={href}>
                    {text}
                </a>
                {children !== undefined && (
                    <div className={styles.expandcollapseicon} onClick={toggleCollapse}>
                        {isOpen && <div>-</div>}
                        {!isOpen && <div>+</div>}
                    </div>
                )}
            </div>
            {isOpen && children !== undefined && <div className={styles.children}>{children}</div>}
        </div>
    );
};

export default NavigationBarItem;
