import { useEffect } from "react";
import { mailtoURL } from "../..";
import { DefaultPage } from "../DefaultPage/DefaultPage";
import Hyperlink from "../Hyperlink/Hyperlink";
import LargeHeadlineItem from "../LargeHeadlineItem/LargeHeadlineItem";
import ListItem from "../ListItem/ListItem";
import TextItem from "../TextItem/TextItem";
import styles from "./PageStyles.module.scss";

export default function DeinNavigatorNutzungsaenderungPage() {
    useEffect(() => {
		document.title = "Bauantrag Nutzungsänderung";
	}, []);

    return (
        <DefaultPage>
            <LargeHeadlineItem margin="2em 0 0 0">Nutzungsänderung</LargeHeadlineItem>
            <div className={styles.sidebysidetop}>
                <img className={styles.imagevertical} src="/assets/gluehlampe.png" />
                <div className={styles.listtop}>
                    <TextItem>
                        Die Änderung des Nutzungsschwerpunktes einer Immobilie steht grundsätzlich der Errichtung eines
                        Gebäudes gleich!
                    </TextItem>
                    <TextItem>
                        Es ist also keinesfalls genehmigungsfrei eine Bürofläche als Wohnung oder eine Wohnfläche als
                        Arztpraxis zu nutzen.
                    </TextItem>
                    <TextItem>
                        Unserer Erfahrung nach sind sich die meisten Ruckzuck Akteure nicht im Klaren darüber, welche
                        rechtlichen und finanziellen Risiken eingegangen werden.
                    </TextItem>
                </div>
            </div>

            <LargeHeadlineItem margin="3em 0 0 0">Prüfe den Zustand Deiner Immobilie</LargeHeadlineItem>
            <TextItem>Aufzählung beispielhaft:</TextItem>
            <TextItem margin="1em 0 0 0">
                <ul className={styles.list}>
                    <ListItem>Die Anforderungen an den vorbeugenden Brandschutz sind nicht ausreichend?</ListItem>
                    <ListItem>Ist eine Prüfung des Tragwerks erforderlich?</ListItem>
                    <ListItem>Ist mit Immissionen zu rechnen?</ListItem>
                    <ListItem>Es sind nicht ausreichend Stellplätze vorhanden?</ListItem>
                </ul>
            </TextItem>

            <TextItem margin="2em 0 0 0">
                Prüfe zusätzlich die Fragestellungen bei{" "}
                <Hyperlink
                    text="Umbau, Sanierung, Modernisierung!"
                    href="/Dein-Navigator/Umbau-Sanierung-Modernisierung/"
                />
            </TextItem>

            <LargeHeadlineItem>Ist bei Nutzungsänderung eine Baugenehmigung erforderlich?</LargeHeadlineItem>
            <TextItem>
                Bereits ein „Ja“ bei den Aufzählungen reicht für ein notwendiges Bauantragsverfahren.
                <br />
                Informiere Dich! Ein <Hyperlink
                    text="Brief vom Bauamt"
                    href="/Dein-Navigator/Der-Brief-vom-Bauamt/"
                />{" "}
                kann auch noch Jahre später Deinen Briefkasten finden.
            </TextItem>

            <TextItem margin="2em 0 0 0">
                <div className={styles.sidebyside}>
                    <TextItem width="40%">
                        <Hyperlink text="Vielen Dank für die Informationen" href="/Dein-Bauantrag" />
                        <br />
                        <Hyperlink text="Ich benötige keine weitere Unterstützung" href="/Dein-Bauantrag" />
                    </TextItem>
                    <TextItem width="50%" textalign="right">
                        <Hyperlink text="Hast Du mehr als eine Frage mit „Ja“ beantwortet?" href={mailtoURL} />
                        <br />

                        <Hyperlink text="Kontaktiere uns per E-Mail oder Anruf." href={mailtoURL} />
                    </TextItem>
                </div>
            </TextItem>

            <TextItem margin="3em 0 0 0">
                <i>
                    <b>Übrigens: </b>
                    Für die Genehmigung einer Nutzungsänderung ist die Einhaltung der aktuell rechtsgültigen
                    öffentlich-rechtlichen Vorschriften notwendig.
                    <br />
                    Je nach Errichtungsdatum können sich wesentliche Beurteilungskriterien geändert haben.
                </i>
            </TextItem>
        </DefaultPage>
    );
}
