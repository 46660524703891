import { useEffect } from "react";
import { DefaultPage } from "../DefaultPage/DefaultPage";
import HeadlineItem from "../HeadlineItem/HeadlineItem";
import LargeHeadlineItem from "../LargeHeadlineItem/LargeHeadlineItem";
import ListItem from "../ListItem/ListItem";
import TextItem from "../TextItem/TextItem";
import styles from "./PageStyles.module.scss";

export default function DeinRatgeberWassindGebaeudeklassenPage() {
    useEffect(() => {
		document.title = "Bauantrag Gebäudeklasse";
	}, []);

    return (
        <DefaultPage>
            <LargeHeadlineItem margin="2em 0 0 0">Was sind Gebäudeklassen?</LargeHeadlineItem>
            <div className={styles.sidebysidetop}>
                <img className={styles.imagevertical} src="/assets/baurecht.png" />
                <div className={styles.listtop}>
                    <TextItem>Rechtsgrundlage: §2 LBO Abs.4</TextItem>
                </div>
            </div>

            <LargeHeadlineItem margin="1.5em 0 1.5em 0">Die Bestimmung der Gebäudeklasse ist abhängig von der:</LargeHeadlineItem>
            <TextItem>
                <ul className={styles.list} style={{ gap: "0.7em" }}>
                    <ListItem>
                        Gebäudestellung (freistehend / oder nicht freistehend)
                    </ListItem>
                    <ListItem>
                        Höhe des Gebäudes (Höhe der letzten Aufenthaltsebene über dem Mittel der Geländeoberfläche)
                    </ListItem>
                    <ListItem>
                        Größe der Nutzungseinheiten (maßgebend ist die Bruttogrundfläche (BGF), ohne Kellerflächen)
                    </ListItem>
                    <ListItem>
                        Anzahl der Nutzungseinheiten
                    </ListItem>
                </ul>
            </TextItem>
            
            <HeadlineItem margin="3em 0 0 0">Gebäudeklasse 1:</HeadlineItem>
            <TextItem margin="0.2em 0 0 0">freistehende Gebäude mit einer Höhe bis zu 7 m und nicht mehr als zwei Nutzungseinheiten von insgesamt nicht mehr als 400 m² und freistehende land- oder forstwirtschaftlich genutzte Gebäude,</TextItem>
            
            <HeadlineItem>Gebäudeklasse 2:</HeadlineItem>
            <TextItem margin="0.2em 0 0 0">Gebäude mit einer Höhe bis zu 7 m und nicht mehr als zwei Nutzungseinheiten von insgesamt nicht mehr als 400 m²,</TextItem>
            
            <HeadlineItem>Gebäudeklasse 3:</HeadlineItem>
            <TextItem margin="0.2em 0 0 0">sonstige Gebäude mit einer Höhe bis zu 7 m,</TextItem>
            
            <HeadlineItem>Gebäudeklasse 4:</HeadlineItem>
            <TextItem margin="0.2em 0 0 0">Gebäude mit einer Höhe bis zu 13 m und Nutzungseinheiten mit jeweils nicht mehr als 400 m²,</TextItem>
            
            <HeadlineItem>Gebäudeklasse 5:</HeadlineItem>
            <TextItem margin="0.2em 0 0 0">sonstige Gebäude einschließlich unterirdischer Gebäude.</TextItem>

        </DefaultPage>
    );
}
