import { useEffect } from "react";
import { DefaultPage } from "../DefaultPage/DefaultPage";
import Hyperlink from "../Hyperlink/Hyperlink";
import LargeHeadlineItem from "../LargeHeadlineItem/LargeHeadlineItem";
import ListItem from "../ListItem/ListItem";
import TextItem from "../TextItem/TextItem";
import styles from "./PageStyles.module.scss";

export default function DeinRatgeberPage() {
    useEffect(() => {
        document.title = "Bauantrag Ratgeber";
    }, []);

    return (
        <DefaultPage>
            <LargeHeadlineItem margin="2em 0 0 0">Dein Ratgeber</LargeHeadlineItem>
            <div className={styles.sidebysidetop}>
                <img className={styles.imagevertical} src="/assets/deinRatgeber.png" />
                <div className={styles.listtop}>
                    <TextItem>
                        Unser Ratgeber versucht (ohne Anspruch auf Vollständigkeit), wichtige Zusammenhänge zu erklären
                        und Begriffe inhaltlich einzuordnen.
                    </TextItem>
                    <TextItem>Du kannst einen Begriff nicht finden, kein Problem kontaktiere uns.</TextItem>
                </div>
            </div>
            <TextItem margin="3em 0 0 0">
                Bei der Vielzahl an Gesetzen kann man leicht den Überblick verlieren. Allein in der Bundesrepublik
                Deutschland gab es im Jahr 2022 insgesamt 1.773 Bundesgesetze mit 50.738 Paragraphen und 2.795
                Bundesrechtsverordnungen mit 42.590 Paragraphen. Wir betrachten im Weiteren nur die Themen rund ums
                Bauen. Grundsätzlich pyramidenartig organisiert steht an der Spitze der Pyramide die Bundesgesetzgebung
                und am breiten Fuß Städte und Gemeinden.
            </TextItem>

            <LargeHeadlineItem>Bundesrepublik Deutschland</LargeHeadlineItem>
            <ul className={styles.list} style={{ gap: "0.7em" }}>
                <ListItem margin="1em 0 0 0">
                    <Hyperlink text="Baugesetzbuch" href="/Dein-Ratgeber/Bundesgesetze"></Hyperlink> (BauGB)
                </ListItem>
                <ListItem>
                    <Hyperlink text="Baunutzungsverordnung" href="/Dein-Ratgeber/Bundesgesetze"></Hyperlink> (BauNVO)
                </ListItem>
                <ListItem>Raumordnungsgesetz (ROG)</ListItem>
            </ul>

            <LargeHeadlineItem>Bundesland Baden-Württemberg</LargeHeadlineItem>
            <ul className={styles.list} style={{ gap: "0.7em" }}>
                <ListItem margin="1em 0 0 0">
                    <Hyperlink text=" Landesbauordnung" href="/Dein-Ratgeber/Landesgesetze"></Hyperlink> (LBO)
                </ListItem>
                <ListItem>
                    <Hyperlink
                        text="Verfahrensverordnung zur Landesbauordnung"
                        href="/Dein-Ratgeber/Landesgesetze"
                    ></Hyperlink>{" "}
                    (LBOVVO)
                </ListItem>
                <ListItem>
                    <Hyperlink
                        text="Allgemeine Ausführungsverordnung zur Landesbauordnung"
                        href="/Dein-Ratgeber/Landesgesetze"
                    ></Hyperlink>{" "}
                    (LBOAVO)
                </ListItem>
                <ListItem>
                    Verordnungen für verschiedene Bereiche (BauSVO, CPIVO, FeuVO, GaVO, VkVO, FliegBautenZuVO, EltBauVO,
                    VStättVO)
                </ListItem>
                <ListItem>Verwaltungsvorschriften von Brandschutz bis Statistik</ListItem>
                <ListItem>Richtlinien, Erlasse und Hinweise</ListItem>
                <ListItem>Vordrucke für bauliche Verfahren</ListItem>
            </ul>

            <LargeHeadlineItem>Städte und Gemeinden</LargeHeadlineItem>
            <ListItem margin="0.7em 0 0 0">
                Regional begrenzte unterschiedlichste Verordnungen und Satzungen von Baumschutz bis Vergnügungsstätten
            </ListItem>

            <TextItem margin="2em 0 0 0">
                <i>
                    <b>Übrigens: </b>
                    Beginnst Du Deine Recherche beim Bauamt Deiner Stadt oder Deiner Gemeinde, findest Du alle
                    relevanten Informationen zu Deinem Baugrundstück oder Deiner Immobilie im planungsrechtlichen
                    Informationssystem.
                </i>
            </TextItem>
        </DefaultPage>
    );
}
