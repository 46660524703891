import { useEffect } from "react";
import { mailtoURL } from "../..";
import { DefaultPage } from "../DefaultPage/DefaultPage";
import HeadlineItem from "../HeadlineItem/HeadlineItem";
import Hyperlink from "../Hyperlink/Hyperlink";
import LargeHeadlineItem from "../LargeHeadlineItem/LargeHeadlineItem";
import ListItem from "../ListItem/ListItem";
import TextItem from "../TextItem/TextItem";
import styles from "./PageStyles.module.scss";

export default function DeinNavigatorUmbauSanierungModernisierungPage() {
    useEffect(() => {
        document.title = "Bauantrag Umbau Sanierung Modernisierung";
    }, []);

    return (
        <DefaultPage>
            <LargeHeadlineItem margin="2em 0 0 0">Umbau Sanierung Modernisierung</LargeHeadlineItem>
            <div className={styles.sidebysidetop}>
                <img className={styles.imagevertical} src="/assets/gluehlampe.png" />
                <div className={styles.listtop}>
                    <TextItem>
                        Mit diesen drei Wörtern verbindest Du sicherlich nicht sofort ein Baugenehmigungsverfahren.
                    </TextItem>
                    <TextItem>
                        Neue Fenster und Türen, neue Heizung, eine neue Dachdeckung und neuer Außenputz (beides
                        natürlich mit Wärmedämmung), den alten Balkon weg, dafür einen neuen Carport und noch einige
                        andere kleine Änderungen.
                    </TextItem>
                </div>
            </div>

            <LargeHeadlineItem margin="3em 0 0 0">Prüfe den Zustand Deiner Immobilie</LargeHeadlineItem>
            <TextItem>Aufzählung beispielhaft:</TextItem>
            <TextItem margin="1em 0 0 0">
                <ul className={styles.list}>
                    <ListItem>Gibt es wesentliche Abweichungen zum letzten genehmigten Planungsstand?</ListItem>
                    <ListItem>
                        Findet eine <Hyperlink text="Nutzungsänderung" href="/Dein-Navigator/Nutzungsaenderung/" />{" "}
                        statt?
                    </ListItem>
                    <ListItem>Sind bei den Bauarbeiten öffentlich-rechtlichen Vorschriften betroffen?</ListItem>
                    <ListItem>Ist das Erneuerbare-Wärme-Gesetz (EWärmeG) zu beachten?</ListItem>
                    <ListItem>Ist die Energiesparverordnung (EnEV) zu beachten?</ListItem>
                    <ListItem>Werden Bäume gefällt?</ListItem>
                </ul>
            </TextItem>

            <LargeHeadlineItem>Ist bei Umbau Sanierung Modernisierung eine Baugenehmigung erforderlich?</LargeHeadlineItem>

            <TextItem>
                Bereits ein „Ja“ bei den Aufzählungen reicht für ein notwendiges Bauantragsverfahren.
                <br />
                Informiere Dich! Ein <Hyperlink
                    text="Brief vom Bauamt"
                    href="/Dein-Navigator/Der-Brief-vom-Bauamt/"
                />{" "}
                kann auch noch Jahre später Deinen Briefkasten finden.
            </TextItem>

            <TextItem margin="2em 0 0 0">
                <div className={styles.sidebyside}>
                    <TextItem width="40%">
                        <Hyperlink text="Vielen Dank für die Informationen" href="/Dein-Bauantrag/" />
                        <br />
                        <Hyperlink text="Ich benötige keine weitere Unterstützung" href="/Dein-Bauantrag/" />
                    </TextItem>
                    <TextItem width="50%" textalign="right">
                        <Hyperlink text="Hast Du mehr als eine Frage mit „Ja“ beantwortet?" href={mailtoURL} />
                        <br />

                        <Hyperlink text="Kontaktiere uns per E-Mail oder Anruf." href={mailtoURL} />
                    </TextItem>
                </div>
            </TextItem>

            <TextItem margin="3em 0 0 0">
                <i>
                    <b>Übrigens: </b>
                    Manches ist verfahrensfrei, anderes vielleicht nicht. Es lohnt sich, über den Umfang der Bauarbeiten
                    nachzudenken, bevor die Handwerker beginnen. Meist reicht ein Termin vor Ort, um die Sachlage zu
                    klären.
                </i>
            </TextItem>
        </DefaultPage>
    );
}
