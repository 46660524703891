import { ReactNode } from "react"
import styles from '../Pages/PageStyles.module.scss';

type Props = {
    children: ReactNode,
    width?: string,
    margin?: string, 
}

export default function LargeHeadlineItem({children, margin, width}: Props){
    return <div  style={{width: width, margin: margin, overflowWrap: "break-word"}} className={styles.largeheadline}>
        {children}
    </div>
}