import styles from "./NavigationBar.module.scss";
import HamburgerIcon from "../HamburgerIcon/HamburgerIcon";
import NavigationBarItem from "./NavigationBarItem/NavigationBarItem";

export default function NavigationBar() {
    const ToggleHamburgerMenu = () => {
        let element = document.getElementById("navigationBar") as HTMLDivElement;
        handleScroll(element.style.width === '100%');
        element.style.width = element.style.width === '100%' ? '0' : '100%';
    };
    //disable scroll of the page when the navigation menu opens
	const handleScroll = (scroll: boolean) => {
		document.body.style.overflowY = scroll ? "unset" : "hidden" ;
	};

    return (
        <div className={styles.navigationbar}>
            <div id="navigationBar" className={styles.hamburgermenu}>
                <div className={styles.hamburgercontent}>
                    <NavigationBarItem href="/Dein-Bauantrag/" text="Dein Bauantrag"/>
                    <NavigationBarItem href="/Dein-Architekt/" text="Dein Architekt">
                        <NavigationBarItem href="/Dein-Architekt/Kontakt/" text="Kontakt!"/>
                    </NavigationBarItem>
                    <NavigationBarItem href="/Dein-Navigator/" text="Dein Navigator">
                        <NavigationBarItem href="/Dein-Navigator/Abbruch/" text="Abbruch"/>
                        <NavigationBarItem href="/Dein-Navigator/Neubau-Anbau/" text="Neubau (Anbau)"/>
                        <NavigationBarItem href="/Dein-Navigator/Umbau-Sanierung-Modernisierung/" text="Umbau Sanierung Modernisierung"/>
                        <NavigationBarItem href="/Dein-Navigator/Nutzungsaenderung/" text="Nutzungsänderung"/>
                        <NavigationBarItem href="/Dein-Navigator/Der-Brief-vom-Bauamt/" text="Der Brief vom Bauamt"/>
                    </NavigationBarItem>
                    <NavigationBarItem href="/Dein-Angebot/" text="Dein Angebot"/>
                    <NavigationBarItem href="/Dein-Ratgeber/" text="Dein Ratgeber">
                        <NavigationBarItem href="/Dein-Ratgeber/Bundesgesetze/" text="Bundesgesetze"/>
                        <NavigationBarItem href="/Dein-Ratgeber/Landesgesetze/" text="Landesgesetze"/>
                        <NavigationBarItem href="/Dein-Ratgeber/Was-ist-ein-Bebauungsplan/" text="Was ist ein Bebauungsplan?"/>
                        <NavigationBarItem href="/Dein-Ratgeber/Was-sind-Gebaeudeklassen/" text="Was sind Gebäudeklassen?"/>
                        <NavigationBarItem href="/Dein-Ratgeber/Was-ist-ein-Lageplan/" text="Was ist ein Lageplan?"/>
                        <NavigationBarItem href="/Dein-Ratgeber/Was-sind-Bauvorlagen/" text="Was sind Bauvorlagen?"/>
                        <NavigationBarItem href="/Dein-Ratgeber/Bauamt-Bauverstaendige-Fristen-Baugenehmigung/" text="Bauamt Bauverständige Fristen Baugenehmigung?"/>
                        <NavigationBarItem href="/Dein-Ratgeber/Der-Antrag-auf-Abweichungen,-Ausnahmen-oder-Befreiungen/" text="Der Antrag auf Abweichungen, Ausnahmen oder Befreiungen?"/>
                    </NavigationBarItem>
                </div>
            </div>
            <HamburgerIcon onClick={ToggleHamburgerMenu}/>

        </div>
    );
}
