import { useEffect } from "react";
import { DefaultPage } from "../DefaultPage/DefaultPage";
import HeadlineItem from "../HeadlineItem/HeadlineItem";
import Hyperlink from "../Hyperlink/Hyperlink";
import LargeHeadlineItem from "../LargeHeadlineItem/LargeHeadlineItem";
import ListItem from "../ListItem/ListItem";
import TextItem from "../TextItem/TextItem";
import styles from "./PageStyles.module.scss";

export default function DeinNavigatorNeubauAnbau() {
    useEffect(() => {
		document.title = "Bauantrag Neubau Anbau";
	}, []);
    return (
        <DefaultPage>
            <LargeHeadlineItem margin="2em 0 0 0">Neubau (Anbau)</LargeHeadlineItem>
            <div className={styles.sidebysidetop}>
                <img className={styles.imagevertical} src="/assets/gluehlampe.png" />
                <div className={styles.listtop}>
                    <TextItem>
                        Für alle Bauvorhaben, die von der <Hyperlink text="LBO" href="/Dein-Ratgeber/Landesgesetze" />{" "}
                        nicht ausdrücklich als verfahrensfrei bestimmt sind oder für die das Kenntnisgabeverfahren nicht
                        wählbar ist oder Du Dich gegen das Kenntnisgabeverfahren entschieden hast, ist eine
                        Baugenehmigung zu beantragen.
                    </TextItem>
                    <TextItem>Sind alle örtlichen Verordnungen und Satzungen eingehalten?</TextItem>
                    <TextItem>Ist keine Beteiligung von anderen Fachbehörden erforderlich?</TextItem>
                    <TextItem>
                        Sind keine{" "}
                        <Hyperlink
                            text="Abweichungen"
                            href="/Dein-Ratgeber/Der-Antrag-auf-Abweichungen,-Ausnahmen-oder-Befreiungen/"
                        />
                        ,{" "}
                        <Hyperlink
                            text="Ausnahmen und Befreiungen"
                            href="/Dein-Ratgeber/Der-Antrag-auf-Abweichungen,-Ausnahmen-oder-Befreiungen/"
                        />{" "}
                        zu beantragen?
                    </TextItem>
                    <TextItem>Dann wähle das einfachste Verfahren!</TextItem>
                </div>
            </div>

            <LargeHeadlineItem margin="3em 0 0 0">Verfahrensarten</LargeHeadlineItem>
            <TextItem>
                <ul className={styles.list}>
                    <ListItem>
                        <b>das Baugenehmigungsverfahren:</b>
                        <TextItem margin="0.2em 0 0 0">
                            Du beantragst eine Baugenehmigung und das{" "}
                            <Hyperlink
                                text="Bauamt"
                                href="/Dein-Ratgeber/Bauamt-Bauverstaendige-Fristen-Baugenehmigung/"
                            />{" "}
                            prüft alle Punkte umfassend.
                            <br />
                            Dieses Bauantragsverfahren ist für Gebäude der{" "}
                            <Hyperlink text="Gebäudeklassen" href="/Dein-Ratgeber/Was-sind-Gebaeudeklassen/" /> 4 und 5,
                            mit Ausnahme der Wohngebäude sowie Sonderbauten zu wählen. Es kann sinnvoll sein, dieses
                            Verfahren bei anderen{" "}
                            <Hyperlink text="Gebäudeklassen" href="/Dein-Ratgeber/Was-sind-Gebaeudeklassen/" /> zu
                            verwenden.
                        </TextItem>
                    </ListItem>
                    <ListItem margin="0.2em 0 0 0">
                        <b>das Baugenehmigungsverfahren:</b>
                        <TextItem >
                            Du beantragst eine Baugenehmigung und das{" "}
                            <Hyperlink
                                text="Bauamt"
                                href="/Dein-Ratgeber/Bauamt-Bauverstaendige-Fristen-Baugenehmigung/"
                            />{" "}
                            prüft nur bestimmte Punkte.
                            <br />
                            Dieses Bauantragsverfahren wählst Du, wenn keine Zweifel an der Einhaltung aller
                            öffentlich-rechtlichen Vorschriften bestehen. Es ist möglich, Anträge auf{" "}
                            <Hyperlink
                                text="Abweichungen"
                                href="/Dein-Ratgeber/Der-Antrag-auf-Abweichungen,-Ausnahmen-oder-Befreiungen/"
                            />
                            ,{" "}
                            <Hyperlink
                                text="Ausnahmen oder Befreiungen"
                                href="/Dein-Ratgeber/Der-Antrag-auf-Abweichungen,-Ausnahmen-oder-Befreiungen/"
                            />{" "}
                            zu beantragen. Der räumliche Geltungsbereich ist nicht beschränkt.
                        </TextItem>
                    </ListItem>
                    <ListItem margin="0.2em 0 0 0">
                        <b>das Kenntnisgabe Verfahren:</b>
                        <TextItem>
                            Du informierst das{" "}
                            <Hyperlink
                                text="Bauamt"
                                href="/Dein-Ratgeber/Bauamt-Bauverstaendige-Fristen-Baugenehmigung/"
                            />{" "}
                            umfassend, mehr ist nicht notwendig. Das Bauamt wird nicht weiter tätig. Dieses Verfahren
                            wählst Du, wenn keine Zweifel an der Einhaltung der öffentlich-rechtlichen Vorschriften
                            bestehen und keine Anträge auf{" "}
                            <Hyperlink
                                text="Abweichungen"
                                href="/Dein-Ratgeber/Der-Antrag-auf-Abweichungen,-Ausnahmen-oder-Befreiungen/"
                            />
                            ,{" "}
                            <Hyperlink
                                text="Ausnahmen oder Befreiungen"
                                href="/Dein-Ratgeber/Der-Antrag-auf-Abweichungen,-Ausnahmen-oder-Befreiungen/"
                            />{" "}
                            beantragt werden sollen. Der räumliche Geltungsbereich ist jedoch beschränkt.
                        </TextItem>
                    </ListItem>
                    <ListItem margin="0.2em 0 0 0">
                        <b>das verfahrensfreie Bauvorhaben:</b>
                        <TextItem>
                            Du musst keine Baubehörde informieren, das{" "}
                            <Hyperlink
                                text="Bauamt"
                                href="/Dein-Ratgeber/Bauamt-Bauverstaendige-Fristen-Baugenehmigung/"
                            />{" "}
                            prüft nicht. Du solltest Dir jedoch sicher sein, dass Dein Bauvorhaben in Art und Größe
                            keiner Baugenehmigung bedarf. Nichts ist ärgerlicher als ein{" "}
                            <Hyperlink text="Brief vom Bauamt." href="/Dein-Navigator/Der-Brief-vom-Bauamt/" />
                        </TextItem>
                    </ListItem>
                </ul>
            </TextItem>

            <LargeHeadlineItem>Bauvorlagen</LargeHeadlineItem>
            <TextItem margin="1em 0 0 0">
                <ul className={styles.list} style={{ gap: "0.7em" }}>
                    <ListItem>das Antragsformular (abhängig von der Verfahrenswahl)</ListItem>
                    <ListItem>
                        einen qualifizierten <Hyperlink text="Lageplan" href="/Dein-Ratgeber/Was-ist-ein-Lageplan/" />
                    </ListItem>
                    <ListItem>alle erforderlichen Bauzeichnungen</ListItem>
                    <ListItem>das Formular Baubeschreibung</ListItem>
                    <ListItem>das Formular Technische Angaben über Feuerungsanlagen</ListItem>
                    <ListItem>das Formular Angaben zu gewerblichen Anlagen (bei Gewerbenutzung)</ListItem>
                    <ListItem>
                        das Formular Abfallverwertungskonzept nach § 3 Abs. 4 LKreiWiG (bei Abrissmaßnahmen)
                    </ListItem>
                    <ListItem>
                        den Nachweis der Bauvorlageberechtigung (Architekt / Innenarchitekt / Bauingenieur)
                    </ListItem>
                    <ListItem>die Erklärung zum Standsicherheitsnachweis</ListItem>
                    <ListItem>die Bauleiter/in-Erklärung</ListItem>
                    <ListItem>das Formblatt Statistik der Baugenehmigungen</ListItem>
                </ul>
            </TextItem>

            <TextItem margin="2em">
                <div className={styles.sidebyside}>
                    <TextItem width="40%">
                        <Hyperlink
                            text="Vielen Dank für die Informationen Ich benötige keine weitere Unterstützung"
                            href="/Dein-Bauantrag/"
                        />
                    </TextItem>
                    <TextItem width="40%">
                        <Hyperlink
                            text="Mit unserem Online Angebotsrechner kannst Du schnell ermitteln wieviel Geld Du sparen kannst."
                            href="/Dein-Angebot/"
                        />
                    </TextItem>
                </div>
            </TextItem>

            <TextItem margin="3em 0 0 0">
                <i>
                    <b>Übrigens: </b>
                    Du trägst als Antragsteller die Verantwortung dafür, dass alle öffentlich-rechtlichen Vorschriften
                    eingehalten werden, auch diejenigen, die je nach Wahl des Antragsverfahrens nicht durch das Bauamt
                    geprüft werden. Das Bauamt kann bei Verstößen oder Ordnungswidrigkeiten den Bau stoppen und nach
                    Ermessen bereits Gebautes wieder abreißen lassen. Das ist ärgerlich und kostet meistens viel Geld.
                </i>
            </TextItem>
        </DefaultPage>
    );
}
