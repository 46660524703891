import HorizontalNavigationBar from "../NavigationBar/HorizontalNavigationBar";
import NavigationBar from "../NavigationBar/NavigationBar";
import styles from "./PageHeader.module.scss";
import { useState, useEffect } from 'react';

export default function PageHeader() {
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1200);

    useEffect(() => {
        const handleResize = () => {
          setIsSmallScreen(window.innerWidth < 1200);
        };
    
        // Add event listener to update the screen size when the window is resized.
        window.addEventListener('resize', handleResize);
    
        // Clean up the event listener when the component unmounts.
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
    

    return (
        <div className={styles.pageheader}>

            {isSmallScreen ? <NavigationBar /> : <HorizontalNavigationBar />}

            <a className={styles.headerwrapper} href="/">
                <img className={styles.image} src="/assets/logoImage.png" />
                <div className={styles.headline}>bw-bauantrag.de</div>
                <div className={styles.subheadline}>digital-schnell-zuverlässig</div>
            </a>
        </div>
    );
}
