import { useEffect } from "react";
import { DefaultPage } from "../DefaultPage/DefaultPage";
import HeadlineItem from "../HeadlineItem/HeadlineItem";
import LargeHeadlineItem from "../LargeHeadlineItem/LargeHeadlineItem";
import TextItem from "../TextItem/TextItem";
import styles from "./PageStyles.module.scss";

export default function DeinRatgeberWasisteinLageplanPage() {
    useEffect(() => {
		document.title = "Bauantrag Lageplan";
	}, []);
    return (
        <DefaultPage>
            <LargeHeadlineItem margin="2em 0 0 0">Was ist ein Lageplan?</LargeHeadlineItem>
            <div className={styles.sidebysidetop}>
                <img className={styles.imagevertical} src="/assets/baurecht.png" />
                <div className={styles.listtop}>
                    <TextItem>Rechtsgrundlage: §4 LBOVVO</TextItem>
                    <TextItem>
                        Der Lageplan zum Bauantrag wird im Maßstab 1:500 oder 1:1.000 erstellt. Er verschafft einen
                        generellen Überblick und stellt Dein Bauvorhaben im Nachbarschaftskontext dar.{" "}
                    </TextItem>
                </div>
            </div>

            <TextItem margin="1.5em 0 1.5em 0">
                Bei Neubauvorhaben ist ein qualifizierter Lageplan nach §4 Verfahrensverordnung zur Landesbauordnung
                (LBOVVO) notwendig, während zum Beispiel bei einem Antrag auf Nutzungsänderung ein unqualifizierter
                Lageplan ausreichend ist.
            </TextItem>

            <HeadlineItem>Was ist ein qualifizierter Lageplan?</HeadlineItem>
            <TextItem margin="0.2em 0 0 0">
                Benötigst Du für Deinen Bauantrag einen qualifizierten Lageplan nach §4 LBOVVO, beauftragst Du ein öffentlich bestelltes Vermessungsbüro mit der Erstellung.
            </TextItem>

            <TextItem>
            Der <b>zeichnerische Teil</b> wird auf der Grundlage der aktuellen Liegenschaftskarte gefertigt. Diese wird vor Ort auf Ihre Aktualität überprüft und ggf. aktualisiert. Meist wird auch ein Abstandsflächenplan angefertigt, in dem die Mindestgrenzabstände, welche sich aus den Abstandsflächen ergeben, eingetragen sind.
            </TextItem>

            <TextItem>
            Im <b>schriftlichen Teil</b> werden u.a. die Eigentümer der Nachbargrundstücke, Baulasten, Grund- und Geschossflächenberechnungen gemäß LBOVVO usw. eingetragen. Dabei wird die Einhaltung der gesetzlichen Vorschriften wie Landesbauordnung (LBO) und Baunutzungsverordnung (BauNVO) geprüft.

            </TextItem>
        </DefaultPage>
    );
}
