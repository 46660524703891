import { useEffect } from "react";
import { DefaultPage } from "../DefaultPage/DefaultPage";
import HeadlineItem from "../HeadlineItem/HeadlineItem";
import Hyperlink from "../Hyperlink/Hyperlink";
import LargeHeadlineItem from "../LargeHeadlineItem/LargeHeadlineItem";
import ListItem from "../ListItem/ListItem";
import TextItem from "../TextItem/TextItem";
import styles from "./PageStyles.module.scss";

export default function DeinRatgeberWassindBauvorlagenPage() {
    useEffect(() => {
		document.title = "Bauantrag Bauvorlagen";
	}, []);

    return (
        <DefaultPage>
            <LargeHeadlineItem margin="2em 0 0 0">Was sind Bauvorlagen?</LargeHeadlineItem>
            <div className={styles.sidebysidetop}>
                <img className={styles.imagevertical} src="/assets/baurecht.png" />
                <div className={styles.listtop}>
                    <TextItem>
                        Rechtsgrundlage: <Hyperlink text="LBOVVO" href="/Dein-Ratgeber/Landesgesetze" />
                    </TextItem>
                    <TextItem>
                        Alle Bauvorlagen findest Du im{" "}
                        <Hyperlink
                            text="Serviceportal Baden-Württemberg."
                            href="https://www.service-bw.de/zufi/lebenslagen/5001287?plz=70180&ags=08111000"
                        />
                    </TextItem>
                </div>
            </div>

            <HeadlineItem margin="3em 0 0 0">
                Art, Umfang und Verfasser von Bauvorlagen regelt die Verfahrensverordnung zur Landesbauordnung (LBOVVO).
            </HeadlineItem>
            <HeadlineItem margin="1em 0 0 0">Je nach Antragsauswahl sind das z.B.:</HeadlineItem>
            <TextItem>
                <ul className={styles.list} style={{ gap: "0.7em" }}>
                    <ListItem>Antrag auf Baugenehmigung §49LBO, oder</ListItem>
                    <ListItem>Antrag auf Bauvorbescheid §47LBO, oder</ListItem>
                    <ListItem>Kenntnisgabe Verfahren §51(1+2)LBO, oder</ListItem>
                    <ListItem>Antrag auf Baugenehmigung im vereinfachten Verfahren §52LBO, oder</ListItem>
                    <ListItem>Abbruch baulicher Anlagen §51(3)LBO</ListItem>
                </ul>
            </TextItem>

            <HeadlineItem margin="2em 0 0 0">bei Bedarf folgende zusätzliche Formblätter</HeadlineItem>
            <TextItem>
                <ul className={styles.list} style={{ gap: "0.7em" }}>
                    <ListItem>Baubeschreibung</ListItem>
                    <ListItem>Technische Angaben zu Feuerungsanlagen</ListItem>
                    <ListItem>Angaben zu gewerblichen Anlagen</ListItem>
                </ul>
            </TextItem>

            <HeadlineItem margin="2em 0 0 0">in allen Fällen</HeadlineItem>
            <TextItem>
                <ul className={styles.list} style={{ gap: "0.7em" }}>
                    <ListItem>eine <Hyperlink text="Lageplan" href="/Dein-Ratgeber/Was-ist-ein-Lageplan/"/> Darstellung</ListItem>
                </ul>
            </TextItem>

            <HeadlineItem margin="2em 0 0 0">sowie alle zeichnerischen Darstellungen zum Verständnis des Vorhabens</HeadlineItem>
            <TextItem>
                <ul className={styles.list} style={{ gap: "0.7em" }}>
                    <ListItem>alle Grundrisse</ListItem>
                    <ListItem>mindestens ein Quer- und Längsschnitt</ListItem>
                    <ListItem>alle Ansichten</ListItem>
                    <ListItem>eine Dachaufsicht (nicht in jedem Fall)</ListItem>
                    <ListItem>einen Außenanlagenplan (nicht in jedem Fall)</ListItem>
                    <ListItem>die Grundstücksentwässerung (nicht in jedem Fall)</ListItem>
                </ul>
            </TextItem>
        </DefaultPage>
    );
}
