import React from "react";
import styles from "./HorizontalNavigationBarItem.module.scss";

interface NavigationBarItemProps {
    href: string;
    text: string;
    children?: React.ReactNode;
}

const HorizontalNavigationBarItem: React.FC<NavigationBarItemProps> = ({ href, children, text }) => {
    return (
        <div className={styles.navbaritem}>
            <div className={styles.navbaritemheader}>
                <a className={styles.text} href={href}>
                    {text}
                </a>
            </div>
            {children !== undefined && <div className={styles.children}>{children}</div>}
            {children !== undefined && <span className={styles.downarrow}></span>}
        </div>
    );
};
export default HorizontalNavigationBarItem;